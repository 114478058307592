import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Recursos definidos </ h2>
                <ul>
                    <li> Declaración jurada informal sobre el cuidado de parientes </ li>
                    <li> Servicios de atención domiciliaria y hospitalaria </ li>
                    <li> Plan de educación individualizado (IEP) </ li>
                    <li> Plan 504 </ li>
                    <li> Evaluación del comportamiento funcional (FBA) </ li>
                    <li> Plan de Intervención de Conducta (BIP) </ li>
                    <li> Intimidación </ li>
                    <li> Escuela en el hogar </ li>
                    <li> Ley de asistencia a personas sin hogar McKinney-Vento </ li>
                </ ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}