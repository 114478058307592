import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_5Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Plan 504 </ h2>
                <ul>
                    <li> Un plan 504 es un plan educativo creado por escuelas individuales para atender a los estudiantes con un impedimento físico o mental que limita sustancialmente una o más actividades principales de la vida. Esto puede incluir impedimentos físicos, enfermedades, enfermedades transmisibles, condiciones crónicas y problemas de aprendizaje. El plan 504 describirá las modificaciones y adaptaciones que se necesitarán para que estos estudiantes tengan la oportunidad de desempeñarse al mismo nivel que sus compañeros. Estos alojamientos pueden incluir cosas tales como una rampa para sillas de ruedas, menús especiales para el almuerzo o instrucción en el hogar. </ li>
                </ ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m6">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}