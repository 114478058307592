import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_5 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>Child does not attend school due to "Illegal Alien" status of
                    parents.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school district anonymously to explore how they
  address educating students under this status<br />
                        <br />
                    </li>
                    <li>Explore approved Home Schooling programs
  </li>
                    <li>Child may be approved under a hardship enrollment policy of
                    local school system.
  </li>
                    <li>Child may be approved under the "McKinney Vento Homeless
                    Assistance Act
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>Child is absent due to parent's lack of residential
                    documentation verifying they are a new tenant in the school zone.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school district to explore who's assigned to
  investigate and approve special enrollment cases<br />
                        <br />
                    </li>
                    <li>Visit local school to request direction in handling residency
                    verification
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m6">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}