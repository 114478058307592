import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_Review extends Component {

    render() {

        return (
            <div>
                <h1>Chapter 1 - Laws and Policies - Review</h1>

<h2>State Department of Education Accountabilities</h2>

<ul>
    <li>The Maryland State Department of Education mandates each local school system to have the minimal standard of 94% attendance rate per marking period.</li>
    <li>Each local school system must develop a student attendance policy which includes a general statement regarding the local school system's purpose and rationale for promoting regular school attendance.</li>
</ul>

<h1>Maryland Compulsory School Attendance Law</h1>
                    <p>
                    Article 7-301 of the Annotated Code of Maryland requires every child between the ages of 5 and 18 to attend public school regularly for the academic school year unless otherwise enrolled in private or home school instruction.</p>

               <h1>Duty of Parent/Guardian</h1>          
                <p>Each person who has a child between the ages of 5 and 18 years old, under his/her custody, is held responsible for making sure that child attends school regularly.</p>
   
                <h1>Penalties</h1>
                    <ul>
                        <li>Any parent/guardian who fails to send a child in their custody to school regularly is violating the Maryland Compulsory School Attendance Law.</li>
                            <li>As a result, charges may be filed in the District Court of Maryland by the local school district.</li>
                                <li>Any parent/guardian found guilty of violating the Maryland Compulsory School Attendance Law would be subject to a fine not to exceed $50 per day of unlawful absence, imprisonment not to exceed 10 days, or both, for the first conviction.</li>
                                    <li>Any parent/guardian violating the Maryland Compulsory School Attendance Law for a second or subsequent conviction is subject to a fine not to exceed $100 per day for unlawful absence, imprisonment not to exceed 30 days, or both. Additionally, any children of this parent/guardian may be placed into the custody of the Department of Social Services following their incarceration.</li>
                                        <li>AFDC - A parent/guardian that receives Public Assistance is subject to have benefits reduced by $25 per month if a child in their custody does not attend school 80% of the time.</li>
                    </ul>

                     <h1>Defining Truancy</h1>
                        <ul>
                            <li>Truancy - any unlawful absence of a child from school without the knowledge or permission of the parent/guardian.</li>
                                <li>Habitual Truant - any student who is truant from school for more than 20 percent of any grading period of a school year.</li>
                        </ul>

                 <h1>Defining Lawful and Unlawful Absences</h1>
                        <h2>Lawful Absences - Absences from school are lawful only under the following conditions:</h2>
                            <ul>
                                <li>Death in Family</li>
                                    <li>Illness of the Child</li>
                                        <li>Court Summons</li>
                                            <li>Hazardous Weather Conditions</li>
                                                <li>Work Approved</li>
                                                    <li>Religious Holiday</li>
                                                        <li>State Emergency</li>
                                                            <li>Other Emergency</li>
                                                                <li>Health Exclusion</li>
                                                                    <li>Suspension</li>
                                                                        <li>Lack of Authorized Transportation</li>
                </ul>

                 <h1>Unlawful Absences</h1>
                    <ul>
                        <li>Truancy and all other absences not specifically listed as lawful.</li>  
                    </ul>
                                <b>Examples of Unlawful Absences:</b>
                                    <ul>
                                        <li>Parent/Guardian taking a child on vacation</li>  
                                            <li>Student staying home to provide care for younger sibling or parent</li>  
                                                <li>Student overslept </li>  
                                                    <li>Student exhausted from being awake all night playing game console or watching television</li>  
                                                        <li>Student Birthday</li>  
                                                            <li>Student employment is during school hours</li>  
                                                                <li>Student is homeless and therefore cannot come to school</li>  
</ul>     

                    <h1>Defining Full & Partial Day</h1>
                            <li>Full Day - It is considered a full day when a student is present four or more hours during the school day.</li>

                                <li>Half Day - It is considered  a half day when a student is in attendance for at least 2 hours, but less than 4 hours of the school day</li>

                                    
                                        <h2>Documentation</h2>
                                            <ul>
                                                <li>Parents/Guardians are expected to provide their child's school with documentation of any absence by way of a doctor's note or a note drafted by the parent/guardian</li>
                                                    <li>This note is to be presented to the school upon the child's return to school from an absence.</li>
                                                        <li>Any undocumented absences are considered unexcused and unlawful</li>
                                                            <li>School Principals and/or Pupil Personnel Workers may require notes from a physician for students who have been excessively absent</li>
                                                                <li>Documented absences allow students to be eligible to make up work they missed while they were absent</li>
</ul>

                
                <Countdown date={Date.now() + 120000}>
                    <a className="btn btn-primary" href="/quiz1">Start Quiz</a>
                </Countdown>
            </div>
        )
    }
}