import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_3 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>I refuse to send my child to school regularly due to my
                    frequent need to pick him/her up early from school for disruptive
                    behavior.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore counseling services<br />
                        <br />
                    </li>
                    <li>Consider mentoring programs<br />
                        <br />
                    </li>
                    <li>Request school officials to conduct a Functional Behavioral
                    Assessment (FBA) and determine if the child is eligible for a
  Behavior Intervention Plan (BIP)<br />
                        <br />
                    </li>
                    <li>Request for the child to be placed on a Progress Report<br />

                        <br />
                    </li>
                    <li>Request for the school to team the child to explore if he/she
                    qualifies for IEP services. The child may have an emotional
  disability<br />
                        <br />
                        Print attached <a href="list2.html" target="_blank">"Request
    for an IEP Team"</a> sample letter
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child refuses to attend school or obey my authority.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore Filing Incorrigible Child Reports at your local
  police precinct<br />
                        <br />
                    </li>
                    <li>Explore requesting a District Court judge to file an
                    emergency petition to have the child evaluated at a mental health
                    facility if you suspect him/her to be in danger of harming self
  or others<br />
                        <br />
                    </li>
                    <li>Explore further parenting skills classes<br />
                        <br />
                    </li>
                    <li>Escort the child to school daily and make sure school staff
                    members observe you dropping off the child
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m4">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}