import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_7 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has recently been hospitalized for depression and
                    continues to have difficulty with his/her motivation to attend
                    school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact the school to set up a meeting to address this
  concern and the possible need for:<br />
                        <br />
                    </li>
                    <li>counseling services<br />
                        <br />
                    </li>
                    <li>a modified day school program<br />
                        <br />
                    </li>
                    <li>Home and Hospital Services<br />
                        <br />
                    </li>
                    <li>a Home Schooling program
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has runaway from home and school officials may still
                    file charges against me for violating Maryland's Compulsory
                    School Attendance Law.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>File a runaway report with your local authorities<br />
                        <br />
                    </li>
                    <li>Inform the school that this has happened and what you are
                    doing about it
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m8">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}