import React, { Component } from 'react';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import Home from './components/Home';
import Index from './components/Index';
import About from './components/About';
import Resources from './components/Resources';
import { Route } from 'react-router';
import { LayoutPanel } from './components/Layout';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import MD1 from './components/quiz/Chapter1/MD1';
import MD1_2 from './components/quiz/Chapter1/MD1_2';
import MD1_3 from './components/quiz/Chapter1/MD1_3';
import MD1_4 from './components/quiz/Chapter1/MD1_4';
import MD1_5 from './components/quiz/Chapter1/MD1_5';
import MD1_6 from './components/quiz/Chapter1/MD1_6';
import MD1_7 from './components/quiz/Chapter1/MD1_7';
import MD1_8 from './components/quiz/Chapter1/MD1_8';
import MD1_Review from './components/quiz/Chapter1/MD1_Review';
import MD1Spanish from './components/quiz/Chapter1Spanish/MD1Spanish';
import MD1_2Spanish from './components/quiz/Chapter1Spanish/MD1_2Spanish';
import MD1_3Spanish from './components/quiz/Chapter1Spanish/MD1_3Spanish';
import MD1_4Spanish from './components/quiz/Chapter1Spanish/MD1_4Spanish';
import MD1_5Spanish from './components/quiz/Chapter1Spanish/MD1_5Spanish';
import MD1_6Spanish from './components/quiz/Chapter1Spanish/MD1_6Spanish';
import MD1_7Spanish from './components/quiz/Chapter1Spanish/MD1_7Spanish';
import MD1_8Spanish from './components/quiz/Chapter1Spanish/MD1_8Spanish';
import MD1_ReviewSpanish from './components/quiz/Chapter1Spanish/MD1_ReviewSpanish';
import { Quiz1Spanish } from './components/quiz/Quiz1Spanish';
import { Quiz2Spanish } from './components/quiz/Quiz2Spanish';
import { Quiz3Spanish } from './components/quiz/Quiz3Spanish';
import { Quiz4Spanish } from './components/quiz/Quiz4Spanish';
import { Quiz1 } from './components/Quiz1';
import { Quiz2 } from './components/quiz/Quiz2';
import { Quiz3 } from './components/quiz/Quiz3';
import { Quiz4 } from './components/quiz/Quiz4';
import MD2Film from './components/quiz/Chapter2/MD2Film';
import MD2FilmSpanish  from './components/quiz/Chapter2Spanish/MD2FilmSpanish';
import MD2 from './components/quiz/Chapter2/MD2';
import MD2_2 from './components/quiz/Chapter2/MD2_2';
import MD2_3 from './components/quiz/Chapter2/MD2_3';
import MD2_4 from './components/quiz/Chapter2/MD2_4';
import MD2_5 from './components/quiz/Chapter2/MD2_5';
import MD2_6 from './components/quiz/Chapter2/MD2_6';
import MD2_7 from './components/quiz/Chapter2/MD2_7';
import MD2_8 from './components/quiz/Chapter2/MD2_8';
import MD2_Review from './components/quiz/Chapter2/MD2_Review';
import MD2Spanish from './components/quiz/Chapter2Spanish/MD2Spanish';
import MD2_2Spanish from './components/quiz/Chapter2Spanish/MD2_2Spanish';
import MD2_3Spanish from './components/quiz/Chapter2Spanish/MD2_3Spanish';
import MD2_4Spanish from './components/quiz/Chapter2Spanish/MD2_4Spanish';
import MD2_5Spanish from './components/quiz/Chapter2Spanish/MD2_5Spanish';
import MD2_6Spanish from './components/quiz/Chapter2Spanish/MD2_6Spanish';
import MD2_7Spanish from './components/quiz/Chapter2Spanish/MD2_7Spanish';
import MD2_8Spanish from './components/quiz/Chapter2Spanish/MD2_8Spanish';
import MD2_ReviewSpanish from './components/quiz/Chapter2Spanish/MD2_ReviewSpanish';
import MD3 from './components/quiz/Chapter3/MD3';
import MD3_2 from './components/quiz/Chapter3/MD3_2';
import MD3_3 from './components/quiz/Chapter3/MD3_3';
import MD3_4 from './components/quiz/Chapter3/MD3_4';
import MD3_5 from './components/quiz/Chapter3/MD3_5';
import MD3_6 from './components/quiz/Chapter3/MD3_6';
import MD3_7 from './components/quiz/Chapter3/MD3_7';
import MD3_8 from './components/quiz/Chapter3/MD3_8';
import MD3_9 from './components/quiz/Chapter3/MD3_9';
import MD3_Review from './components/quiz/Chapter3/MD3_Review';
import MD3Spanish from './components/quiz/Chapter3Spanish/MD3Spanish';
import MD3_2Spanish from './components/quiz/Chapter3Spanish/MD3_2Spanish';
import MD3_3Spanish from './components/quiz/Chapter3Spanish/MD3_3Spanish';
import MD3_4Spanish from './components/quiz/Chapter3Spanish/MD3_4Spanish';
import MD3_5Spanish from './components/quiz/Chapter3Spanish/MD3_5Spanish';
import MD3_6Spanish from './components/quiz/Chapter3Spanish/MD3_6Spanish';
import MD3_7Spanish from './components/quiz/Chapter3Spanish/MD3_7Spanish';
import MD3_8Spanish from './components/quiz/Chapter3Spanish/MD3_8Spanish';
import MD3_9Spanish from './components/quiz/Chapter3Spanish/MD3_9Spanish';
import MD3_ReviewSpanish from './components/quiz/Chapter3Spanish/MD3_ReviewSpanish';
import MD4 from './components/quiz/Chapter4/MD4';
import MD4_2 from './components/quiz/Chapter4/MD4_2';
import MD4_3 from './components/quiz/Chapter4/MD4_3';
import MD4_4 from './components/quiz/Chapter4/MD4_4';
import MD4_5 from './components/quiz/Chapter4/MD4_5';
import MD4_6 from './components/quiz/Chapter4/MD4_6';
import MD4_7 from './components/quiz/Chapter4/MD4_7';
import MD4_8 from './components/quiz/Chapter4/MD4_8';
import MD4_Review from './components/quiz/Chapter4/MD4_Review';
import MD4Spanish from './components/quiz/Chapter4Spanish/MD4Spanish';
import MD4_2Spanish from './components/quiz/Chapter4Spanish/MD4_2Spanish';
import MD4_3Spanish from './components/quiz/Chapter4Spanish/MD4_3Spanish';
import MD4_4Spanish from './components/quiz/Chapter4Spanish/MD4_4Spanish';
import MD4_5Spanish from './components/quiz/Chapter4Spanish/MD4_5Spanish';
import MD4_6Spanish from './components/quiz/Chapter4Spanish/MD4_6Spanish';
import MD4_7Spanish from './components/quiz/Chapter4Spanish/MD4_7Spanish';
import MD4_8Spanish from './components/quiz/Chapter4Spanish/MD4_8Spanish';
import MD4_ReviewSpanish from './components/quiz/Chapter4Spanish/MD4_ReviewSpanish';
import MD2Pelicula from './components/quiz/Chapter2Spanish/MD2Pelicula';

const auth = new Auth();

const handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
        auth.handleAuthentication();
    }
}

const App = (props) => {
    return (
      <LayoutPanel auth={auth} {...props}>
        <Route exact path='/' component={Index} />
        <Route exact path='/start' render={(props) => <Home auth={auth} {...props} />} />
        <Route exact path='/about' component={About} />
        <Route exact path='/resources' component={Resources} />
        <Route exact path='/md1' component={MD1} />
        <Route exact path='/counter' component={Counter} />
        <Route exact path='/fetchdata' component={FetchData} />
        <Route exact path='/md1m2' component={MD1_2} />
        <Route exact path='/md1m3' component={MD1_3} />
        <Route exact path='/md1m4' component={MD1_4} />
        <Route exact path='/md1m5' component={MD1_5} />
        <Route exact path='/md1m6' component={MD1_6} />
        <Route exact path='/md1m7' component={MD1_7} />
        <Route exact path='/md1m8' component={MD1_8} />
        <Route exact path='/md1mreview' component={MD1_Review} />
        <Route exact path='/md1spanish' component={MD1Spanish} />
        <Route exact path='/md1m2spanish' component={MD1_2Spanish} />
        <Route exact path='/md1m3spanish' component={MD1_3Spanish} />
        <Route exact path='/md1m4spanish' component={MD1_4Spanish} />
        <Route exact path='/md1m5spanish' component={MD1_5Spanish} />
        <Route exact path='/md1m6spanish' component={MD1_6Spanish} />
        <Route exact path='/md1m7spanish' component={MD1_7Spanish} />
        <Route exact path='/md1m8spanish' component={MD1_8Spanish} />
        <Route exact path='/md1mreviewspanish' component={MD1_ReviewSpanish} />
        <Route exact path='/quiz1' component={Quiz1} />
        <Route exact path='/quiz1spanish' component={Quiz1Spanish} />
        <Route exact path='/quiz2' component={Quiz2} />
        <Route exact path='/quiz2spanish' component={Quiz2Spanish} />
        <Route exact path='/quiz3' component={Quiz3} />
        <Route exact path='/quiz3spanish' component={Quiz3Spanish} />
        <Route exact path='/quiz4' component={Quiz4} />
        <Route exact path='/quiz4spanish' component={Quiz4Spanish} />
        <Route exact path='/md2film' component={MD2Film} />
        <Route exact path='/md2pelicula' component={MD2Pelicula} />
        <Route exact path='/md2filmspanish' component={MD2FilmSpanish} />
        <Route exact path='/md2' component={MD2} />
        <Route exact path='/md2m2' component={MD2_2} />
        <Route exact path='/md2m3' component={MD2_3} />
        <Route exact path='/md2m4' component={MD2_4} />
        <Route exact path='/md2m5' component={MD2_5} />
        <Route exact path='/md2m6' component={MD2_6} />
        <Route exact path='/md2m7' component={MD2_7} />
        <Route exact path='/md2m8' component={MD2_8} />
        <Route exact path='/md2mreview' component={MD2_Review} />
        <Route exact path='/md2spanish' component={MD2Spanish} />
        <Route exact path='/md2m2spanish' component={MD2_2Spanish} />
        <Route exact path='/md2m3spanish' component={MD2_3Spanish} />
        <Route exact path='/md2m4spanish' component={MD2_4Spanish} />
        <Route exact path='/md2m5spanish' component={MD2_5Spanish} />
        <Route exact path='/md2m6spanish' component={MD2_6Spanish} />
        <Route exact path='/md2m7spanish' component={MD2_7Spanish} />
        <Route exact path='/md2m8spanish' component={MD2_8Spanish} />
        <Route exact path='/md2mreviewspanish' component={MD2_ReviewSpanish} />
        <Route exact path='/md3' component={MD3} />
        <Route exact path='/md3m2' component={MD3_2} />
        <Route exact path='/md3m3' component={MD3_3} />
        <Route exact path='/md3m4' component={MD3_4} />
        <Route exact path='/md3m5' component={MD3_5} />
        <Route exact path='/md3m6' component={MD3_6} />
        <Route exact path='/md3m7' component={MD3_7} />
        <Route exact path='/md3m8' component={MD3_8} />
        <Route exact path='/md3m9' component={MD3_9} />
        <Route exact path='/md3mreview' component={MD3_Review} />
        <Route exact path='/md3spanish' component={MD3Spanish} />
        <Route exact path='/md3m2spanish' component={MD3_2Spanish} />
        <Route exact path='/md3m3spanish' component={MD3_3Spanish} />
        <Route exact path='/md3m4spanish' component={MD3_4Spanish} />
        <Route exact path='/md3m5spanish' component={MD3_5Spanish} />
        <Route exact path='/md3m6spanish' component={MD3_6Spanish} />
        <Route exact path='/md3m7spanish' component={MD3_7Spanish} />
        <Route exact path='/md3m8spanish' component={MD3_8Spanish} />
        <Route exact path='/md3m9spanish' component={MD3_9Spanish} />
        <Route exact path='/md3mreviewspanish' component={MD3_ReviewSpanish} />
        <Route exact path='/md4' component={MD4} />
        <Route exact path='/md4m2' component={MD4_2} />
        <Route exact path='/md4m3' component={MD4_3} />
        <Route exact path='/md4m4' component={MD4_4} />
        <Route exact path='/md4m5' component={MD4_5} />
        <Route exact path='/md4m6' component={MD4_6} />
        <Route exact path='/md4m7' component={MD4_7} />
        <Route exact path='/md4m8' component={MD4_8} />
        <Route exact path='/md4mreview' component={MD4_Review} />
        <Route exact path='/md4spanish' component={MD4Spanish} />
        <Route exact path='/md4m2spanish' component={MD4_2Spanish} />
        <Route exact path='/md4m3spanish' component={MD4_3Spanish} />
        <Route exact path='/md4m4spanish' component={MD4_4Spanish} />
        <Route exact path='/md4m5spanish' component={MD4_5Spanish} />
        <Route exact path='/md4m6spanish' component={MD4_6Spanish} />
        <Route exact path='/md4m7spanish' component={MD4_7Spanish} />
        <Route exact path='/md4m8spanish' component={MD4_8Spanish} />
        <Route exact path='/md4mreviewspanish' component={MD4_ReviewSpanish} />
        <Route path="/callback" render={(props) => {
                    handleAuthentication(props);
                    return <Callback {...props} />
        }} />
        </LayoutPanel>
    );  
}

export default App;
