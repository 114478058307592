import React, { Component } from 'react';

export default class Resources extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <h1>Resources</h1>
                <ul>
                    <li><a href="https://www.dropbox.com/s/ebzbln3zp7bytdp/StudentExcusedSickNote.docx?dl=0">Student Excused Absence Note</a></li>
                    <li><a href="https://www.dropbox.com/s/0368gtgwtrcj7b7/Harassment_or_Intimidation_Reporting_Form.docx?dl=0">Bullying Harassment Form</a></li>
                    <li><a href="https://www.dropbox.com/s/3g82yvb70vguf7l/ParentalTransportationReleaseForm.docx?dl=0">Parental Transportation Release Form</a></li>
                </ul>
            </div>
        )
    }
}