import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_6 extends Component {

    render() {

        return (
            <div>
                <h2>Functional Behavioral Assessment (FBA)</h2>
                <ul>
                    <li>A Functional Behavioral Assessment (FBA) is a problem-solving
                    process for addressing problematic student behavior. It develops
                    techniques and strategies to identify the purposes of specific
                    behaviors to help IEP teams select interventions to directly
                    address the behavior problems. A FBA should be integrated into
                    the process of developing, reviewing, and revising a student's
                    IEP. This process should include input from the student,
  parent/guardian, and school staff<br />
                        <br />
                    </li>
                    <li>A FBA focuses on identifying significant and specific social,
                    affective, cognitive, and/or environmental factors associated
                    with the occurrence (and non-occurrence) of specific behaviors of
                    an individual child.
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Behavior Intervention Plan (BIP)</strong>
                </p>
                <ul>
                    <li>A Behavior Intervention Plan (BIP) details how an IEP team
                    will improve difficult behavior that is a barrier to a child's
                    academic success. A BIP is a document that describes just how an
                    IEP team will help a child improve his/her behavior in instances
                    when he/she can't focus, doesn't complete work, disrupts the
                    classroom, and/or is constantly in trouble. A BIP is in order
                    when a child's behavior disrupts the classroom and significantly
                    interrupts his/her education.
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m7">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}