import React, { Component } from 'react';
import { Button } from 'antd';

export default class Home extends Component {

    constructor(props){
        super(props);

    }

    goTo(route) {
        this.props.history.replace(`/${route}`)
    }

    login() {
        this.props.auth.login();
    }

    logout() {
        this.props.auth.logout();
    }


    render() {
        const { isAuthenticated } = this.props.auth;
        console.log(this.props);
        console.log(isAuthenticated);
        return (
            <div>
                {
                    !isAuthenticated() && (
                        <div>
                            <Button
                            className="btn-margin"
                            onClick={this.login.bind(this)}
                        >
                            Log In
                        </Button>
                            </ div>
                    )
                }
                {
                    isAuthenticated() && (
                        <div>
                            <h2>Take the Test in English</h2>
                            <a className="btn btn-primary" href="/md1">Start Chapter</a>
                            <h2>Tome el examen en Español</h2>
                            <a className="btn btn-primary" href="/md1spanish">Iniciar el capítulo</a>
                        </div>
                    )
                }
                </div>
        )
    }
}
