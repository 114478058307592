import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_4 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has difficulty waking up in the morning.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore different methods to assist child in awaking for
                    school (i.e. alarm clock, get the child up earlier to get ready
  for school, get the child to bed earlier, etc...)<br />
                        <br />
                    </li>
                    <li>Have child seen by a pediatrician for a possible sleeping
  disorder<br />
                        <br />
                    </li>
                    <li>Investigate whether the child would be eligible for a Home
  and Hospital program due to a sleeping disorder<br />
                        <br />
                    </li>
                    <li>Consider placing the child in a Home Schooling program
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has been absent recently and is now residing with
                    other relatives due to a family hardship.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Relative should contact local school system to explore
  hardship enrollment policies<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child under Informal
  Kinship Care Affidavit.<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child under McKinney-Vento
  Homeless Assistance Act<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child at local school
                    under the approved school district's non-resident enrollment
                    policy
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m5">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}