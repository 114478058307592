import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_2 extends Component {

    render() {

        return (
            <div>
                <h2>Student Dropout Factors</h2>
                    <ul>
                        <li>Poor academic performance</li>
                            <li>Alcohol and drug use</li>
                                <li>Unmet mental health needs</li>
                                    <li>Lack of educational goals</li>
                                        <li>Poor physical health</li>
                                            <li>Truant friends</li>
                                                <li>Teen pregnancy</li>
                                                    <li>Lack of childcare </li>
                                                        <li>Lack of sleep</li>
                                                        </ul>
                                                        
                                                        <Countdown date={Date.now() + 15000}>
                                                        <a className="btn btn-primary" href="/md2m3">Next Slide</a>                                     
            </Countdown>
            </div>
        )
    }
}