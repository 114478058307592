import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_7 extends Component {

    render() {

        return (
            <div>
                <h2>Economic Impact</h2>
                    <ul>
                        <li>Schools lose state educational funding due to high dropout rate</li>
                            <li>Dropouts have a higher probability to be unemployed</li>
                                <li>Dropouts have a higher probability to be receiving Cash or Medical Assistance from the State Government.</li>
                                    <li>Dropouts have a higher probability to be incarcerated and state taxpayers fray the housing cost.</li>
                                        <li>Dropouts have a higher probability to be living in poverty.</li>
                                            <li>The more education an individual attains, the more potential he/she has for increasing their potential salary. </li>
                                                <li>See Economic Salary Graph (next page)</li>
                                                </ul>
                    
                                                <Countdown date={Date.now() + 15000}>
                                                <a className="btn btn-primary" href="/md2m8">Next Slide</a>
                                                </Countdown>
            </div>
        )
    }
}