import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q4",
            questions: [
                {
                    id: 1,
                    text: 'An Informal Kinship Care affidavit allows for relatives other than biological parents/legal guardians to enroll a child in a Maryland public school',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 2,
                    text: 'According to the Informal Kinship Care Affidavit, the applicant must be a relative who provides care of a child seven days a week, twenty-four hours a day, due to a family hardship involving a biological parent/legal guardian.',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 3,
                    text: 'Home and Hospital Services can be provided to students with',
                    choices: [{
                        id: 'a',
                        text: 'Diagnosed emotional conditions',
                    },
                    {
                        id: 'b',
                        text: 'Diagnosed physical conditions',
                    },
                    {
                        id: 'c',
                        text: 'Neither given response',
                    }, {
                        id: 'd',
                        text: 'Both given responses',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 4,
                    text: 'A child whose parent/guardian voluntarily moves out of state would meet the criteria for a hardship enrollment by a relative',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 5,
                    text: 'A Functional Behavioral Assessment (FBA) is typically created with input from',
                    choices: [{
                        id: 'a',
                        text: 'Parent/Guardian',
                    },
                    {
                        id: 'b',
                        text: 'Student',
                    }, {
                        id: 'c',
                        text: 'School staff',
                    }, {
                        id: 'd',
                        text: 'All of the above',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 6,
                    text: 'A 504 Plan is an educational plan that services students with a physical or mental impairment which substantially limits one or more major life activities',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 7,
                    text: ' __________ is defined as being exposed to repeated, negative actions on the part of one or more other persons over time when he/she has difficulty defending himself/herself',
                    choices: [{
                        id: 'a',
                        text: 'Home Schooling',
                    },
                    {
                        id: 'b',
                        text: 'Bullying',
                    },
                    {
                        id: 'c',
                        text: 'Dropout',
                    },
                    {
                        id: 'd',
                        text: 'Special education',
        }
                    ],
                    correct: 'b'
                }, {
                    id: 8,
                    text: 'A Behavior Intervention Plan (BIP) is utilized to assist only students that have a learning disability',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 9,
                    text: 'An Individualized Education Plan (IEP) provides additional educational services based on the needs of a child identified with a disability and is mandated by the federal government',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 10,
                    text: 'The following would be an example of bullying',
                    choices: [{
                        id: 'a',
                        text: 'A student bumps into another student purposely in the hall',
                    },
                    {
                        id: 'b',
                        text: 'A student is picked last for a team in gym class',
                    },
                    {
                        id: 'c',
                        text: 'A student demands another student\'s lunch money every morning ',
                    },
                    {
                        id: 'd',
                        text: 'A student feels she would be made fun of if others were made aware of her sexual orientation',
                    }
                    ],
                    correct: 'c'
                }, {
                    id: 11,
                    text: 'A parent who dropped out of high school is still entitled to home school his/her child',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 12,
                    text: 'The McKinney-Vento Homeless Assistance Act would apply to families living in',
                    choices: [{
                        id: 'a',
                        text: 'Shelters',
                    },
                    {
                        id: 'b',
                        text: 'Motels',
                    },
                    {
                        id: 'c',
                        text: 'Neither given response',
                    },
                    {
                        id: 'd',
                        text: 'Both given responses',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 13,
                    text: 'A student may be eligible for Home and Hospital Services due to',
                    choices: [{
                        id: 'a',
                        text: 'Pregnancy',
                    },
                    {
                        id: 'b',
                        text: 'School phobia',
                    }, {
                        id: 'c',
                        text: 'Cancer',
                    }, {
                        id: 'd',
                        text: 'All of the above',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 14,
                    text: 'A 504 Plan may be appropriate for a child who',
                    choices: [{
                        id: 'a',
                        text: 'Is in a wheelchair',
                    },
                    {
                        id: 'b',
                        text: 'Chooses to be habitually truant',
                    },
                    {
                        id: 'c',
                        text: 'Has a family history of drop out',
                    },
                    {
                        id: 'd',
                        text: 'All of the above',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 15,
                    text: 'The following would be considered a family hardship on the Informal Kinship Care Affidavit',
                    choices: [{
                        id: 'a',
                        text: 'Incarceration of parent/legal guardian',
                    },
                    {
                        id: 'b',
                        text: 'Death of parent/legal guardian',
                    },
                    {
                        id: 'c',
                        text: 'Neither given response',
                    },
                    {
                        id: 'd',
                        text: 'Both given responses',
                    }
                    ],
                    correct: 'd'
                },
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}