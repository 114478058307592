import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_6 extends Component {

    render() {

        return (
            <div>
                <h1>Defining Lawful and Unlawful Absences</h1>
                        <h2>Lawful Absences - Absences from school are lawful only under the following conditions:</h2>
                            <ul>
                                <li>Death in Family</li>
                                    <li>Illness of the Child</li>
                                        <li>Court Summons</li>
                                            <li>Hazardous Weather Conditions</li>
                                                <li>Work Approved</li>
                                                    <li>Religious Holiday</li>
                                                        <li>State Emergency</li>
                                                            <li>Other Emergency</li>
                                                                <li>Health Exclusion</li>
                                                                    <li>Suspension</li>
                                                                        <li>Lack of Authorized Transportation</li>
                </ul>
            
                
                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m7">Next Slide</a>
                </Countdown>
            </div>)
    }
}