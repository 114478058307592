import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q3",
            questions: [
                {
    id: 1,
    text: 'Truancy is a clear risk factor for',
    choices: [
        {
            id: 'a',
            text: 'Substance abuse',
        },
        {
            id: 'b',
            text: 'Delinquent behavior',
        },
        {
            id: 'c',
            text: 'Teen pregnancy',
        },
        {
            id: 'd',
            text: 'All of the above',
        }
    ],
    correct: 'd'
},
{
    id: 2,
    text: 'Public schools may lose their funding due to high rates of truancy and drop out.',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'a'
},
{
    id: 3,
    text: 'Teen pregnancy increases the chance a student will drop out',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'a'
},
{
    id: 4,
    text: 'Push Out policies consist of suspensions and automatic failing grades as consequences for truancy',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'a'
},
{
    id: 5,
    text: 'Dropping out has been found to be linked to low achievement rates',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'a'
},
{
    id: 6,
    text: 'Students who live in single-parent households are at a higher risk for dropout',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'a'
},
{
    id: 7,
    text: ' A high school drop out\'s average yearly salary is ______ less than that of a college graduate with a Bachelor\'s Degree',
    choices: [
        {
            id: 'a',
            text: 'Over $30,000.00',
        },
        {
            id: 'b',
            text: 'Over $50,000.00',
        },
        {
            id: 'c',
            text: 'Over Under $10,000.00',
        },
        {
            id: 'd',
            text: 'Under 20,000.00',
        }
    ],
    correct: 'a'
},
{
    id: 8,
    text: 'The following has been found to be a factor in student drop out rate',
    choices: [
        {
            id: 'a',
            text: 'Student employment',
        },
        {
            id: 'b',
            text: 'Family history of drop out',
        },
        {
            id: 'c',
            text: 'Neither given response',
        },
        {
            id: 'd',
            text: 'Both given responses',
        }
    ],
    correct: 'd'
},
{
    id: 9,
    text: ' Drop outs and high school graduates share similar incarceration rates',
    choices: [
        {
            id: 'a',
            text: 'True',
        },
        {
            id: 'b',
            text: 'False',
        }
    ],
    correct: 'b'
},
{
    id: 10,
    text: 'A high school drop out\'s average yearly salary is',
    choices: [
        {
            id: 'a',
            text: 'Over $20,000.00',
        },
        {
            id: 'b',
            text: 'Over $40,000.00',
        },
        {
            id: 'c',
            text: 'Under $10,000.00',
        },
        {
            id: 'd',
            text: 'Under 20,000.00',
        }
    ],
    correct: 'd'
},
{
    id: 11,
    text: 'What percentage of high school drop outs end up in prison',
    choices: [
        {
            id: 'a',
            text: '7 out of 10',
        },
        {
            id: 'b',
            text: '1 out of 10',
        },
        {
            id: 'c',
            text: '8 out of 10',
        },
        {
            id: 'd',
            text: '3 out of 10',
        }
    ],
    correct: 'c'
}
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}
