import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_8 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is habitually truant due to difficulties with her
                    pregnancy.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact the school about your concerns<br />
                        <br />
                    </li>
                    <li>Explore whether or not she is eligible for a modified day
  school program to reduce her time in school<br />
                        <br />
                    </li>
                    <li>Complete Home and Hospital paperwork in an effort to get her
                    placed on Home and Hospital earlier than expected (requires
                    doctor's approval)
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child physically fights with me when I attempt to make
                    him/her attend school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>File assault charges with your local authorities<br />
                        <br />
                    </li>
                    <li>File an incorrigible youth report with your local
  authorities<br />
                        <br />
                    </li>
                    <li>Explore whether mental health services/therapy are needed for
  the child<br />
                        <br />
                    </li>
                    <li>Enroll in further parenting classes for more ideas on how to
  work positively with your child<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is habitually truant due to the fact that I leave
                    for work before he/she leaves for school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Get the child to utilize an alarm clock in order to get
  himself/herself awake on time<br />
                        <br />
                    </li>
                    <li>Contact the child at home from work or in route to work in an
  effort to awaken him/her<br />
                        <br />
                    </li>
                    <li>Contact your child's school daily to make sure he/she is in
  attendance<br />
                        <br />
                    </li>
                    <li>Consistently provide consequences when your child does not
  attend school<br />
                        <br />
                    </li>
                    <li>Seek assistance from family members or neighbors to get your
                    child to school on time daily
  </li>
                </ul>

                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4mreview">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}