import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_8Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo suele ausentarse sin permiso debido a dificultades con ella
                    el embarazo.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con la escuela sobre sus inquietudes <br />
                        <br />
                    </ li>
                    <li> Explore si es elegible o no para un día modificado
  programa escolar para reducir su tiempo en la escuela <br />
                        <br />
                    </ li>
                    <li> Complete el papeleo del Hogar y del Hospital en un esfuerzo por conseguirla
                    colocado en el hogar y el hospital antes de lo esperado (requiere
                    aprobación del doctor)
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo pelea físicamente conmigo cuando intento hacer
                    él / ella asiste a la escuela.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> presentar cargos de asalto con las autoridades locales <br />
                        <br />
                    </ li>
                    <li> Presente un informe juvenil incorregible con su representante local
  autoridades <br />
                        <br />
                    </ li>
                    <li> Explore si se necesitan servicios / terapia de salud mental para
  el niño <br />
                        <br />
                    </ li>
                    <li> Inscríbase en más clases para padres para obtener más ideas sobre cómo
  trabaje positivamente con su hijo <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo es habitualmente ausente debido al hecho de que me voy
                    para el trabajo antes de que él / ella se vaya a la escuela.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Haga que el niño utilice una alarma para obtener
  él / ella despierto a tiempo <br />
                        <br />
                    </ li>
                    <li> Póngase en contacto con el niño en casa desde el trabajo o de camino al trabajo en un
  esfuerzo por despertarlo <br />
                        <br />
                    </ li>
                    <li> Comuníquese con la escuela de su hijo todos los días para asegurarse de que esté en
  asistencia <br />
                        <br />
                    </ li>
                    <li> Proporcione consecuentemente consecuencias cuando su hijo no
  asistir a la escuela <br />
                        <br />
                    </ li>
                    <li> Busque ayuda de familiares o vecinos para obtener su
                    niño a la escuela a tiempo todos los días
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4mreview">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}