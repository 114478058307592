import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_7Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Intimidación </ h2>
                <ul>
                    <li> La intimidación está presente cuando un alumno está expuesto a repeticiones,
                    acciones negativas por parte de una o más personas sobre
                    tiempo, y él o ella tiene dificultad para defenderse o
  ella misma. <br />
                        <br />
                    </ li>
                    <li> Implica un comportamiento agresivo asociado con el no deseado,
                    acciones negativas; un patrón de comportamiento repetido a lo largo del tiempo; y
                    un desequilibrio de poder o fuerza.
  </ li>
                </ ul>
                <p>
                    <br />
                    
                        <strong> Tipos de intimidación </ strong>
                </ p>
                <ul>
                    <li>
                        
                                Acoso cibernético (a través del teléfono celular o Internet) <br />
                                <br />
                    </ li>
                    <li>
                       Ser amenazado o ser obligado a hacer actos <br />
                                <br />
                    </ li>
                    <li>
                               Intimidación verbal (incluyendo comentarios derogatorios y nombre
    llamando) <br />
                                <br />
                    </ li>
                    <li>
                               Bullying a través de mentiras y la propagación de rumores <br />
                                <br />
                    </ li>
                    <li>
                              Intimidación por exclusión social o aislamiento <br />
                                <br />
                    </ li>
                    <li>
                               Acoso racial <br />
                                <br />
                    </ li>
                    <li>
                               Tener dinero u otros artículos personales tomados o dañados <br />
                                <br />
                    </ li>
                    <li>
                                Bullying físico (incluyendo golpes, patadas, empujones y
    escupir) <br />
                                <br />
                    </ li>
                    <li>
                               Bullying sexual
                    </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m8">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}