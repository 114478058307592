import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_8 extends Component {

    render() {

        return (
            <div>
                <h2>Placeholder</h2>


<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2mreview">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}