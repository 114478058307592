import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4Review extends Component {

    render() {

        return (
            <div>
                <h2>Chapter 4 Review</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>I refuse to send my child to school, due to him/her regularly
  being bullied.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Inform school administrator to address bullying
  concerns<br />
                        <br />
                    </li>
                    <li>Request a Parent Conference<br />
                        <br />
                    </li>
                    <li>Attempt to get a school guidance counselor involved to work
                    with both students involved in the conflict. The school guidance
                    counselor may attempt to resolve the conflict through mediation
  and/or conflict resolution<br />
                        <br />
                    </li>
                    <li>File Bullying/Harassment Report at local school
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Actions schools may take in response to allegations of
  bullying</strong><br />
                </p>
                <ul>
                    <li>School administrators must investigate reports of bullying
  within 2 school days of receiving a completed <a href=
                            "Harassment_or_Intimidation_Reporting_Form.docx">"BULLYING,
  HARASSMENT OR INTIMIDATION REPORTING FORM"</a> (Word doc)<br />
                        <br />
                    </li>
                    <li>Peer Mediation<br />
                        <br />
                    </li>
                    <li>Restructuring of class schedules<br />
                        <br />
                    </li>
                    <li>Required parent conferences with all involved parties<br />
                        <br />
                    </li>
                    <li>Local suspension<br />
                        <br />
                    </li>
                    <li>Suspension to the Board of Education<br />
                        <br />
                    </li>
                    <li>Involvement of local police department<br />
                        <br />
                    </li>
                    <li>Other
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>It is impossible for my child to attend school regularly due
  to a chronic illness or physical disability.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Student may be eligible for a modified school program<br />
                        <br />
                    </li>
                    <li>Explore whether the child is eligible for a 504 Plan<br />
                        <br />
                    </li>
                    <li>Explore Home &amp; Hospital Services<br />
                        <br />
                    </li>
                    <li>Enroll in an approved Home Schooling program
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child refuses to attend school regularly due to him/her
  struggling academically.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Set-Up Teacher Conference<br />
                        <br />
                    </li>
                    <li>Explore free tutoring services<br />
                        <br />
                    </li>
                    <li>Request for your child to be teamed to explore if he/she
                    qualifies for additional educational services. Child may have an
  educational disability that qualifies for IEP services<br />
                        <br />
                        Print attached <a href="list2.html" target="_blank">"Request
    for an IEP Team"</a> sample letter
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>I refuse to send my child to school regularly due to my
                    frequent need to pick him/her up early from school for disruptive
                    behavior.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore counseling services<br />
                        <br />
                    </li>
                    <li>Consider mentoring programs<br />
                        <br />
                    </li>
                    <li>Request school officials to conduct a Functional Behavioral
                    Assessment (FBA) and determine if the child is eligible for a
  Behavior Intervention Plan (BIP)<br />
                        <br />
                    </li>
                    <li>Request for the child to be placed on a Progress Report<br />

                        <br />
                    </li>
                    <li>Request for the school to team the child to explore if he/she
                    qualifies for IEP services. The child may have an emotional
  disability<br />
                        <br />
                        Print attached <a href="list2.html" target="_blank">"Request
    for an IEP Team"</a> sample letter
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child refuses to attend school or obey my authority.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore Filing Incorrigible Child Reports at your local
  police precinct<br />
                        <br />
                    </li>
                    <li>Explore requesting a District Court judge to file an
                    emergency petition to have the child evaluated at a mental health
                    facility if you suspect him/her to be in danger of harming self
  or others<br />
                        <br />
                    </li>
                    <li>Explore further parenting skills classes<br />
                        <br />
                    </li>
                    <li>Escort the child to school daily and make sure school staff
                    members observe you dropping off the child
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has difficulty waking up in the morning.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Explore different methods to assist child in awaking for
                    school (i.e. alarm clock, get the child up earlier to get ready
  for school, get the child to bed earlier, etc...)<br />
                        <br />
                    </li>
                    <li>Have child seen by a pediatrician for a possible sleeping
  disorder<br />
                        <br />
                    </li>
                    <li>Investigate whether the child would be eligible for a Home
  and Hospital program due to a sleeping disorder<br />
                        <br />
                    </li>
                    <li>Consider placing the child in a Home Schooling program
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has been absent recently and is now residing with
                    other relatives due to a family hardship.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Relative should contact local school system to explore
  hardship enrollment policies<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child under Informal
  Kinship Care Affidavit.<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child under McKinney-Vento
  Homeless Assistance Act<br />
                        <br />
                    </li>
                    <li>Relative may be approved to enroll child at local school
                    under the approved school district's non-resident enrollment
                    policy
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>Child does not attend school due to "Illegal Alien" status of
                    parents.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school district anonymously to explore how they
  address educating students under this status<br />
                        <br />
                    </li>
                    <li>Explore approved Home Schooling programs
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>Child is absent due to parent's lack of residential
                    documentation verifying they are a new tenant in the school zone.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school district to explore who's assigned to
  investigate and approve special enrollment cases<br />
                        <br />
                    </li>
                    <li>Visit local school to request direction in handling residency
                    verification
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has been absent recently due to the family
                    relocating to a new area because of financial hardship.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school officials to see if you meet the criteria to
                    have your child approved under the McKinney-Vento Homeless
  Assistance Act<br />
                        <br />
                    </li>
                    <li>Parent may consider transferring the child to the school in
                    the new attendance zone
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is afraid to attend school and has been diagnosed
                    with school phobia.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Work with the school to get the child enrolled in a Home and
  Hospital program<br />
                        <br />
                    </li>
                    <li>Discuss the possibility of a modified school day for your
  child with the school administration<br />
                        <br />
                    </li>
                    <li>Enroll child in a Home Schooling program
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has recently been hospitalized for depression and
                    continues to have difficulty with his/her motivation to attend
                    school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact the school to set up a meeting to address this
  concern and the possible need for:<br />
                        <br />
                    </li>
                    <li>counseling services<br />
                        <br />
                    </li>
                    <li>a modified day school program<br />
                        <br />
                    </li>
                    <li>Home and Hospital Services<br />
                        <br />
                    </li>
                    <li>a Home Schooling program
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has runaway from home and school officials may still
                    file charges against me for violating Maryland's Compulsory
                    School Attendance Law.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>File a runaway report with your local authorities<br />
                        <br />
                    </li>
                    <li>Inform the school that this has happened and what you are
                    doing about it
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is habitually truant due to difficulties with her
                    pregnancy.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact the school about your concerns<br />
                        <br />
                    </li>
                    <li>Explore whether or not she is eligible for a modified day
  school program to reduce her time in school<br />
                        <br />
                    </li>
                    <li>Complete Home and Hospital paperwork in an effort to get her
                    placed on Home and Hospital earlier than expected (requires
                    doctor's approval)
  </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child physically fights with me when I attempt to make
                    him/her attend school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>File assault charges with your local authorities<br />
                        <br />
                    </li>
                    <li>File an incorrigible youth report with your local
  authorities<br />
                        <br />
                    </li>
                    <li>Explore whether mental health services/therapy are needed for
  the child<br />
                        <br />
                    </li>
                    <li>Enroll in further parenting classes for more ideas on how to
  work positively with your child<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <br />
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is habitually truant due to the fact that I leave
                    for work before he/she leaves for school.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Get the child to utilize an alarm clock in order to get
  himself/herself awake on time<br />
                        <br />
                    </li>
                    <li>Contact the child at home from work or in route to work in an
  effort to awaken him/her<br />
                        <br />
                    </li>
                    <li>Contact your child's school daily to make sure he/she is in
  attendance<br />
                        <br />
                    </li>
                    <li>Consistently provide consequences when your child does not
  attend school<br />
                        <br />
                    </li>
                    <li>Seek assistance from family members or neighbors to get your
                    child to school on time daily
  </li>
                </ul>
                <Countdown date={Date.now() + 120000}>
                <a className="btn btn-primary" href="/quiz4">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}