import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2 extends Component {

    render() {

        return (
            <div>
                <h2>Staggering Statistics </h2>
                <a href="https://ibb.co/V2q3gbZ"><img src="https://i.ibb.co/Tmgr4JS/2014-salary-projections.jpg" alt="2014-salary-projections" border="0"></img></a>
                    <ul>
                        <li>Every 26 seconds a student drops out of school</li>
                            <li>Every year, more than 1 million children drop out of school</li>
                                <li>8 out of 10 dropouts end up in prison</li>
                                    <li>The United States spends approximately 40 billion dollars each year on prisoner incarceration</li>
                                        <li>A high school dropout will earn 1 million dollars less over their lifetime than a college graduate</li>
                                            <li>High school dropouts cost tax payers billions of dollars in lost wages, welfare, unemployment, and crime related expenses</li>
                                                <li>Source: Mattie C. Stewart Foundation, Dr. Shelley Stewart, 2009</li>
                    </ul> 
                    <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md2m2">Next Slide</a>    
                    </Countdown>                                 
            </div>
        )
    }
}