import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_4 extends Component {

    render() {

        return (
            <div>
                <h1>Penalties</h1>
                <ul>
                    <li>Any parent/guardian who fails to send a child in their custody to school regularly is violating the Maryland Compulsory School Attendance Law.</li>
                    <li>As a result, charges may be filed in the District Court of Maryland by the local school district.</li>
                    <li>Any parent/guardian found guilty of violating the Maryland Compulsory School Attendance Law would be subject to a fine not to exceed $50 per day of unlawful absence, community service or imprisonment not to exceed 3 days, or both, for the first conviction.</li>
                    <li>Any parent/guardian violating the Maryland Compulsory School Attendance Law for a second or subsequent conviction is subject to a fine not to exceed $100 per day for unlawful absence, community service, or imprisonment not to exceed 5 days, or both. Additionally, any children of this parent/guardian may be placed into the custody of the Department of Social Services following their incarceration.</li>
                    <li>AFDC - A parent/guardian that receives Public Assistance is subject to have benefits reduced by $25 per month if a child in their custody does not attend school 80% of the time.</li>
                </ul>



                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m5">Next Slide</a>
                </Countdown>
            </div>
        );
    }
}