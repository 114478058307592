import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz2Spanish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q3Spanish",
            questions: [
                {
                                        id: 1,
                                        text: 'La absentismo escolar es un claro factor de riesgo para',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Abuso de sustancias',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Comportamiento delictivo',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'embarazo adolescente',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Todo lo anterior',
                                                }
                                        ],
                                        correct: 'd'
                                },
                                {
                                        id: 2,
                                        text: 'Las escuelas públicas pueden perder su financiamiento debido a las altas tasas de absentismo escolar y abandono escolar',
                                            choices: [
                                                    {
                                                            id: 'a',
                                                            text: 'Verdadero',
                                                    },
                                                    {
                                                            id: 'b',
                                                            text: 'Falso',
                                                    }
                                            ],
                                        correct: 'a'
                                },
                                {
                                        id: 3,
                                        text: 'El embarazo adolescente aumenta las posibilidades de que un alumno abandone',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 4,
                                        text: 'Las políticas de exclusión consisten en suspensiones y calificaciones reprobables automáticas como consecuencia del absentismo escolar',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 5,
                                        text: 'Descartar se ha encontrado vinculado a bajas tasas de rendimiento',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                {
                                        id: 6,
                                        text: 'Los estudiantes que viven en hogares monoparentales corren mayor riesgo de desertar',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 7,
                                        text: 'El salario anual promedio de un alumno de la escuela secundaria es ______ menos que el de un graduado universitario con un título de licenciatura',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Más de $ 30,000.00',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Más de $ 50,000.00',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Más de $ 10,000',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Menos de 20,000.00',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 8,
                                        text: 'Se ha encontrado que lo siguiente es un factor en la tasa de abandono del estudiante',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Empleo estudiantil',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Historial familiar de abandono',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Ninguna respuesta dada',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Ambas respuestas dadas',
                                                }
                                        ],
                                        correct: 'd'
                                },
                                {
                                        id: 9,
                                        text: 'Los desertores y los graduados de la escuela secundaria comparten tasas de encarcelamiento similares',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 10,
                                        text: 'El salario anual promedio de un alumno de la escuela secundaria es',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Más de $ 20,000.00',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Más de $ 40,000.00',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Menos de $ 10,000.00',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Menos de 20,000.00',
                                                }
                                        ],
                                        correct: 'd'
                                },
                {
                    id: 11,
                    text: '¿Qué porcentaje de abandonos de la escuela secundaria terminan en prisión',
                    choices: [
                        {
                            id: 'a',
                            text: '7 de 10',
                        },
                        {
                            id: 'b',
                            text: '1 de 10',
                        },
                        {
                            id: 'c',
                            text: '8 de 10',
                        },
                        {
                            id: 'd',
                            text: '3 de cada 10',
                        }
                    ],
                    correct: 'c'
                }
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}
