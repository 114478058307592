import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_7Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha sido hospitalizado recientemente por depresión y
                    sigue teniendo dificultades con su motivación para asistir
                    colegio.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con la escuela para programar una reunión para abordar este tema
  preocupación y la posible necesidad de: <br />
                        <br />
                    </ li>
                    <li> servicios de asesoramiento <br />
                        <br />
                    </ li>
                    <li> un programa de escuela diurna modificado <br />
                        <br />
                    </ li>
                    <li> Servicios de hogar y hospital <br />
                        <br />
                    </ li>
                    <li> un programa de escolarización en el hogar
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se ha escapado de casa y las autoridades escolares aún pueden
                    presentar cargos contra mí por violar la obligatoriedad de Maryland
                    Ley de Asistencia Escolar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Archive un informe fuera de control con sus autoridades locales <br />
                        <br />
                    </ li>
                    <li> Informe a la escuela que esto ha sucedido y que es usted
                    haciendo al respecto
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m8">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}