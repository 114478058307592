import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_3 extends Component {

    render() {

        return (
            <div>
                <h2>Home and Hospital Services</h2>
                <ul>
                    <li>
                        <strong>Home and Hospital Services-</strong> The Code of
                        Maryland Regulations (COMAR) 13A.03.05.01 states: "These
                        regulations, which establish a minimum requirement, apply to
                        the provision of instructional services to public school
                        students who are unable to participate in their school of
                        enrollment due to a physical or emotional condition."
  </li>
                </ul>
                <p>
                    <strong>How to obtain a Home and Hospital Application:</strong>
                </p>
                <ul>
                    <li>Please contact the Home and Hospital Coordinator in your
                    local school district. Review attached Master List to locate the
                    local Home and Hospital Coordinator for your respective school
  district.<br />
                        <br />
                    </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m4">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}