import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_Review extends Component {

    render() {

        return (
            <div>
                <h2> Chapter 3 - Review </h2>
                <p>
                    The following terms will be defined in order to create a better
                    understanding of the material covered in the following chapter.
                    These terms are crucial to the learning environment of many of
                    our students across the country.
</p>
                <ul>
                    <li>Informal Kinship Care Affidavit
  </li>
                    <li>Home and Hospital Services
  </li>
                    <li>Individualized Education Plan (IEP)
  </li>
                    <li>504 Plan
  </li>
                    <li>Functional Behavioral Assessment (FBA)
  </li>
                    <li>Behavior Intervention Plan (BIP)
  </li>
                    <li>Bullying
  </li>
                    <li>Home Schooling
  </li>
                    <li>McKinney-Vento Homeless Assistance Act
  </li>
                </ul>
                <p>
                    <strong>Informal Kinship Care Affidavit</strong>
                </p>
                <ul>
                    <li>
                        <strong>Informal Kinship Care-</strong> refers to a living
                        arrangement in which a relative of a child, who is not in the
                        care, custody, or guardianship of the local department of
                        social services, provides for the care and custody of the child
                        due to serious family hardship, 24 hours a day, seven days a
                        week. This only applies if the parent/guardian facing the
                        hardship is a Maryland resident.
  </li>
                </ul>
                <p>
                    <strong>Eligibility of Family Hardships</strong>
                </p>
                <ul>
                    <li>Death of parent or legal guardian of the child.
  </li>
                    <li>Serious illness of parent or legal guardian.
  </li>
                    <li>Drug addiction of parent or legal guardian
  </li>
                    <li>Incarceration of parent or legal guardian
  </li>
                    <li>Abandonment of the child
  </li>
                    <li>Parent or legal guardian assignment to active military
  duty.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Documents requested to determine Approval Status of
  Informal Kinship Care Affidavit:</strong>
                </p>
                <ul>
                    <li>Copy of parent/legal guardian death certificate.
  </li>
                    <li>Medical documentation of serious illness from doctor of
                    parent/legal guardian
  </li>
                    <li>Documentation from detention center where parent/legal
                    guardian is incarcerated.
  </li>
                    <li>Other documentation from various agencies such as social
                    services, courts, military, etc� that verifies parent/legal
                    guardian hardship.
  </li>
                </ul>
                <p>
                    <strong>Determining School Placement:</strong>
                </p>
                <ul>
                    <li>The relative of a child within the 5th degree of blood or
                    marriage must provide care within the school attendance zone in
                    which he/she currently resides.
  </li>
                </ul>
                <p>
                    <strong>Obtaining Informal Kinship Care Affidavit:</strong>
                </p>
                <ul>
                    <li>Local Board of Education
  </li>
                    <li>Department of Social Services
  </li>
                    <li>Local Office of Aging
  </li>
                </ul>
                <p>
                    <strong>Home and Hospital Services</strong>
                </p>
                <ul>
                    <li>
                        <strong>Home and Hospital Services-</strong> The Code of
                        Maryland Regulations (COMAR) 13A.03.05.01 states: "These
                        regulations, which establish a minimum requirement, apply to
                        the provision of instructional services to public school
                        students who are unable to participate in their school of
                        enrollment due to a physical or emotional condition."
  </li>
                </ul>
                <p>
                    <strong>How to obtain a Home and Hospital Application:</strong>
                </p>
                <ul>
                    <li>Please contact the Home and Hospital Coordinator in your
                    local school district. Review attached Master List to locate the
                    local Home and Hospital Coordinator for your respective school
  district.<br />
                        <br />
                        <a href="list1.html" target="_blank">Click here to view a list
    of Maryland Counties Home and Hospital Offices</a>
                    </li>
                </ul>
                <p>
                    <strong>Individualized Education Plan (IEP)</strong>
                </p>
                <ul>
                    <li>An Individualized Education Plan (IEP) is a plan created by
                    an individual school in an effort to meet the unique educational
                    needs of a child who has a disability, as defined by federal
                    regulations. The IEP is intended to aid children in reaching
                    educational goals easier than they otherwise would and is used as
                    a resource by teachers and related service providers.
  </li>
                </ul>
                <p>
                    <strong>504 Plan</strong>
                </p>
                <ul>
                    <li>A 504 plan is an educational plan created by individual
                    schools to service students with a physical or mental impairment
                    which substantially limits one or more major life activities.
                    This can include physical impairments, illnesses, communicable
                    diseases, chronic conditions, and learning problems. The 504 plan
                    will outline the modifications and accommodations that will be
                    needed for these students to have an opportunity to perform at
                    the same level as their peers. These accommodations could include
                    such things as a wheelchair ramp, special lunch menus, or home
                    instruction.
  </li>
                </ul>
                <p>
                    <strong>Functional Behavioral Assessment (FBA)</strong>
                </p>
                <ul>
                    <li>A Functional Behavioral Assessment (FBA) is a problem-solving
                    process for addressing problematic student behavior. It develops
                    techniques and strategies to identify the purposes of specific
                    behaviors to help IEP teams select interventions to directly
                    address the behavior problems. A FBA should be integrated into
                    the process of developing, reviewing, and revising a student's
                    IEP. This process should include input from the student,
                    parent/guardian, and school staff
  </li>
                    <li>A FBA focuses on identifying significant and specific social,
                    affective, cognitive, and/or environmental factors associated
                    with the occurrence (and non-occurrence) of specific behaviors of
                    an individual child.
  </li>
                </ul>
                <p>
                    <strong>Behavior Intervention Plan (BIP)</strong>
                </p>
                <ul>
                    <li>A Behavior Intervention Plan (BIP) details how an IEP team
                    will improve difficult behavior that is a barrier to a child's
                    academic success. A BIP is a document that describes just how an
                    IEP team will help a child improve his/her behavior in instances
                    when he/she can't focus, doesn't complete work, disrupts the
                    classroom, and/or is constantly in trouble. A BIP is in order
                    when a child's behavior disrupts the classroom and significantly
                    interrupts his/her education.
  </li>
                </ul>
                <p>
                    <strong>Bullying</strong>
                </p>
                <ul>
                    <li>Bullying is present when a student is exposed to repeated,
                    negative actions on the part of one or more other persons over
                    time, and he or she has difficulty defending himself or herself.
  </li>
                    <li>It involves aggressive behavior associated with unwanted,
                    negative actions; a pattern of behavior repeated over time; and
                    an imbalance of power or strength.
  </li>
                </ul>
                <p>
                    <strong>Types of Bullying</strong>
                </p>
                <ul>
                    <li>Cyber bullying (via cell phone or Internet)
  </li>
                    <li>Being threatened or being forced to do acts
  </li>
                    <li>Verbal bullying (including derogatory comments and name
                    calling)
  </li>
                    <li>Bullying through lies and the spread of rumors
  </li>
                    <li>Bullying through social exclusion or isolation
  </li>
                    <li>Racial bullying
  </li>
                    <li>Having money or other personal items taken or damaged
  </li>
                    <li>Physical bullying (including hitting, kicking, shoving, and
                    spitting)
  </li>
                    <li>Sexual bullying
  </li>
                </ul>
                <p>
                    <strong>Home Schooling</strong>
                </p>
                <ul>
                    <li>Any parent/guardian may instruct a child at home under the
                    supervision of the local school system or through correspondence
  courses under the supervision of a nonpublic school.<br />
                        <br />
                    </li>
                    <li>Any parent/guardian who desires to home school a child should
                    contact the home schooling coordinator for the local school
                    system in which the child resides. The parent/guardian must
                    initially complete and sign a "Home Schooling Notification Form"
                    to indicate his/her intent to home school a child and to comply
                    with the home schooling regulations. Each year thereafter, the
                    parent/guardian must verify the continuation of home schooling
                    with the local superintendent of schools or the supervising
  nonpublic school/institution.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>McKinney-Vento Homeless Assistance Act</strong>
                </p>
                <p>
                    This act applies to any person who:
</p>
                <ul>
                    <li>Lacks a regular, fixed and adequate nighttime residence (sub
                    standard housing)
  </li>
                    <li>Is sharing housing due to economic struggles (doubled-up)
  </li>
                    <li>Is living in a shelter, hotel or motel
  </li>
                    <li>Is living in a public place not designed for sleeping (cars,
                    parks)
  </li>
                    <li>Is an unaccompanied youth
  </li>
                    <li>Is a child or youth awaiting foster care placement
  </li>
                    <li>Is a child or youth abandoned in a hospital
  </li>
                    <li>Is a migrant child who qualifies under any of the above
  </li>
                </ul>
                <Countdown date={Date.now() + 120000}>
                <a className="btn btn-primary" href="/quiz3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}