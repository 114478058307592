import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_6Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Impacto social. </h2>
                <ul>
                    <li> Desconexi n de compa eros y comunidad escolar </li>
                    <li> Aislamiento de actividades extracurriculares </li>
                    <li> Expulsi n escolar debido a un comportamiento delictivo </li>
                    <li> Deserci n </li>
                    <li> Complacencia con la vida sin escuela </li>
                    <li> Eliminaci n del sistema de apoyo escolar </li>

                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m7spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
        )
    }
}