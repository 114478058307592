import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_ReviewSpanish extends Component {

    render() {

        return (
            <div>
                <h2> Capítulo 4 Revisión </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Me niego a enviar a mi hijo a la escuela, debido a que él / ella regularmente
  siendo intimidado. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Informar al administrador de la escuela para abordar el acoso
  preocupaciones <br />
                        <br />
                    </ li>
                    <li> Solicitar una conferencia para padres <br />
                        <br />
                    </ li>
                    <li> Intento que un consejero de orientación escolar participe para trabajar
                    con ambos estudiantes involucrados en el conflicto. La guía de la escuela
                    consejero puede intentar resolver el conflicto a través de la mediación
  y / o resolución de conflictos <br />
                        <br />
                    </ li>
                    <li> Informe de hostigamiento / acoso escolar en la escuela local
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Acciones que las escuelas pueden tomar en respuesta a las denuncias de
  bullying </ strong> <br />
                </ p>
                <ul>
                    <li> Los administradores escolares deben investigar los informes de intimidación
  dentro de 2 días escolares de haber recibido <a href="Harassment_or_Intimidation_Reporting_Form.docx"> "INTIMIDACIÓN,
  FORMULARIO DE NOTIFICACIÓN DE HOSTIGAMIENTO O INTIMIDACIÓN "</a> (documento de Word) <br />
                        <br />
                    </ li>
                    <li> Mediación entre pares <br />
                        <br />
                    </ li>
                    <li> Reestructuración de horarios de clase <br />
                        <br />
                    </ li>
                    <li> Conferencias de padres obligatorias con todas las partes involucradas <br />
                        <br />
                    </ li>
                    <li> Suspensión local <br />
                        <br />
                    </ li>
                    <li> Suspensión a la Junta de Educación <br />
                        <br />
                    </ li>
                    <li> Participación del departamento de policía local <br />
                        <br />
                    </ li>
                    <li> Otros
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Es imposible para mi hijo asistir a la escuela regularmente debido
  a una enfermedad crónica o discapacidad física. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> El alumno puede ser elegible para un programa escolar modificado <br />
                        <br />
                    </ li>
                    <li> Explore si el niño es elegible para un Plan 504 <br />
                        <br />
                    </ li>
                    <li> Explore Home & amp; Servicios hospitalarios <br />
                        <br />
                    </ li>
                    <li> Inscríbase en un programa de educación en el hogar aprobado
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se niega a asistir a la escuela regularmente debido a él / ella
  luchando académicamente. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Conferencia de docentes de configuración <br />
                        <br />
                    </ li>
                    <li> Explora servicios de tutoría gratis <br />
                        <br />
                    </ li>
                    <li> Solicitud para que su hijo se asocie para explorar si él / ella
                    califica para servicios educativos adicionales. El niño puede tener un
  discapacidad educativa que califica para servicios de IEP <br />
                        <br />
                        Imprimir adjunto <a href="list2.html" target="_blank"> "Solicitud
    para una carta de muestra del Equipo IEP "</a>
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Me niego a enviar a mi hijo a la escuela regularmente debido a mi
                    necesidad frecuente de recogerlo temprano de la escuela por problemas
                    comportamiento.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore servicios de asesoramiento <br />
                        <br />
                    </ li>
                    <li> Considere los programas de tutoría <br />
                        <br />
                    </ li>
                    <li> Solicitar a los funcionarios de la escuela que realicen un comportamiento funcional
                    Evaluación (FBA) y determinar si el niño es elegible para un
  Plan de Intervención de Conducta (BIP) <br />
                        <br />
                    </ li>
                    <li> Solicitud para que el niño sea colocado en un Informe de progreso <br />

                        <br />
                    </ li>
                    <li> Solicitar a la escuela que combine al niño para explorar si él / ella
                    califica para servicios de IEP. El niño puede tener una emoción
  discapacidad <br />
                        <br />
                        Imprimir adjunto <a href="list2.html" target="_blank"> "Solicitud
    para una carta de muestra del Equipo IEP "</a>
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se niega a asistir a la escuela u obedecer mi autoridad.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore la presentación de informes de niños incorregibles en su localidad
  precinto policial <br />
                        <br />
                    </ li>
                    <li> Explore solicitando a un juez del tribunal de distrito que presente un
                    petición de emergencia para que el niño sea evaluado a nivel de salud mental
                    instalación si sospecha que él / ella está en peligro de lastimarse
  u otros <br />
                        <br />
                    </ li>
                    <li> Explora más clases de habilidades para padres <br />
                        <br />
                    </ li>
                    <li> Acompañe al niño a la escuela todos los días y asegúrese de que el personal de la escuela
                    los miembros observan que dejas al niño
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo tiene dificultades para levantarse por la mañana.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore diferentes métodos para ayudar al niño a despertarse
                    escuela (es decir, despertador, levante al niño más temprano para prepararse
  para la escuela, haga que el niño se acueste más temprano, etc.) <br />
                        <br />
                    </ li>
                    <li> Haga que un pediatra vea al niño para un posible sueño
  trastorno <br />
                        <br />
                    </ li>
                    <li> Investigue si el niño sería elegible para un Hogar
  y programa hospitalario debido a un trastorno del sueño <br />
                        <br />
                    </ li>
                    <li> Considere colocar al niño en un programa de educación en el hogar
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha estado ausente recientemente y ahora reside con
                    otros parientes debido a una dificultad familiar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Familiar debe contactar al sistema escolar local para explorar
  políticas de inscripción en condiciones difíciles <br />
                        <br />
                    </ li>
                    <li> Pariente puede ser aprobado para inscribir a un niño bajo Informal
  Declaración jurada de Kinship Care. <br />
                        <br />
                    </ li>
                    <li> Un familiar puede ser aprobado para inscribir a un niño bajo McKinney-Vento
  Ley de asistencia a personas sin hogar <br />
                        <br />
                    </ li>
                    <li> Pariente puede ser aprobado para inscribir a un niño en la escuela local
                    bajo la inscripción de no residentes del distrito escolar aprobado
                    política
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> El niño no asiste a la escuela debido al estado de "Extranjero ilegal" de
                    padres
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con el distrito escolar de forma anónima para explorar cómo
  dirigirse a educar a los estudiantes bajo este estado <br />
                        <br />
                    </ li>
                    <li> Explore programas aprobados de educación en el hogar
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> El niño está ausente debido a la falta de residencia de los padres
                    documentación que verifica que son un nuevo inquilino en la zona escolar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Comuníquese con el distrito escolar para explorar quién está asignado a
  investigar y aprobar casos especiales de inscripción <br />
                        <br />
                    </ li>
                    <li> Visite la escuela local para solicitar dirección para el manejo de la residencia
                    verificación
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha estado ausente recientemente debido a la familia
                    reubicarse en una nueva área debido a dificultades financieras.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con los funcionarios de la escuela para ver si cumple con los criterios para
                    haga que su hijo sea aprobado bajo McKinney-Vento Homeless
  Ley de Asistencia <br />
                        <br />
                    </ li>
                    <li> Los padres pueden considerar transferir al niño a la escuela en
                    la nueva zona de asistencia
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo tiene miedo de ir a la escuela y ha sido diagnosticado
                    con fobia a la escuela
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Trabaja con la escuela para que el niño se inscriba en una casa y
  Programa hospitalario <br />
                        <br />
                    </ li>
                    <li> Discuta la posibilidad de un día escolar modificado para su
  niño con la administración escolar <br />
                        <br />
                    </ li>
                    <li> Inscribir a un niño en un programa de escolarización en el hogar
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha sido hospitalizado recientemente por depresión y
                    sigue teniendo dificultades con su motivación para asistir
                    colegio.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con la escuela para programar una reunión para abordar este tema
  preocupación y la posible necesidad de: <br />
                        <br />
                    </ li>
                    <li> servicios de asesoramiento <br />
                        <br />
                    </ li>
                    <li> un programa de escuela diurna modificado <br />
                        <br />
                    </ li>
                    <li> Servicios de hogar y hospital <br />
                        <br />
                    </ li>
                    <li> un programa de escolarización en el hogar
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se ha escapado de casa y las autoridades escolares aún pueden
                    presentar cargos contra mí por violar la obligatoriedad de Maryland
                    Ley de Asistencia Escolar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Archive un informe fuera de control con sus autoridades locales <br />
                        <br />
                    </ li>
                    <li> Informe a la escuela que esto ha sucedido y que es usted
                    haciendo al respecto
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo suele ausentarse sin permiso debido a dificultades con ella
                    el embarazo.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con la escuela sobre sus inquietudes <br />
                        <br />
                    </ li>
                    <li> Explore si es elegible o no para un día modificado
  programa escolar para reducir su tiempo en la escuela <br />
                        <br />
                    </ li>
                    <li> Complete el papeleo del Hogar y del Hospital en un esfuerzo por conseguirla
                    colocado en el hogar y el hospital antes de lo esperado (requiere
                    aprobación del doctor)
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo pelea físicamente conmigo cuando intento hacer
                    él / ella asiste a la escuela.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> presentar cargos de asalto con las autoridades locales <br />
                        <br />
                    </ li>
                    <li> Presente un informe juvenil incorregible con su representante local
  autoridades <br />
                        <br />
                    </ li>
                    <li> Explore si se necesitan servicios / terapia de salud mental para
  el niño <br />
                        <br />
                    </ li>
                    <li> Inscríbase en más clases para padres para obtener más ideas sobre cómo
  trabaje positivamente con su hijo <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo es habitualmente ausente debido al hecho de que me voy
                    para el trabajo antes de que él / ella se vaya a la escuela.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Haga que el niño utilice una alarma para obtener
   él / ella despierto a tiempo <br />
                        <br />
                    </ li>
                    <li> Póngase en contacto con el niño en casa desde el trabajo o de camino al trabajo en un
   esfuerzo por despertarlo <br />
                        <br />
                    </ li>
                    <li> Comuníquese con la escuela de su hijo todos los días para asegurarse de que esté en
   asistencia <br />
                        <br />
                    </ li>
                    <li> Proporcione consecuentemente consecuencias cuando su hijo no
   asistir a la escuela <br />
                        <br />
                    </ li>
                    <li> Busque ayuda de familiares o vecinos para obtener su
                    niño a la escuela a tiempo todos los días
   </ li>
                </ ul>
                <Countdown date={Date.now() + 120000}>
                <a className="btn btn-primary" href="/md3m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}