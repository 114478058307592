import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_2Spanish extends Component {

    render() {

        return (
            <div>
                <h1>Ley de asistencia obligatoria de Maryland — artículo 7-301</h1>
                <p>
                Definición: Cada persona que tiene la custodia legal o el cuidado y el control de un niño que está entre 5 y 18 años debe ver que el niño asiste a la escuela o recibe una instrucción de la educación alternativa aprobada por el Ministerio de Asuntos Exteriores de Maryland de la Educación</p>
                
                <p>Cualquier persona que tenga custodia legal o cuidado y control de un niño de 5 años de edad y menores de 16 
es culpable de un delito menor si descuidan enviar a su hijo a la escuela diariamente y la mayoría de las ausencias fueron codificadas sin excusa por el sistema escolar local.</p>

                <ul>
                    <li>La primera condena está sujeta a una multa que no exceda $50 dólares por día de ausencia o encarcelamiento ilícitos que no excedan de 3 días o ambos.</li>
                    <li>Second or subsequent conviction is subject to a fine not to exceed $100 per day of unlawful absences or imprisonment not to exceed 5 days, or both.</li>

                </ul>
                <Countdown date={Date.now() + 15000}>
                                <a className="btn btn-primary" href="/md1m3spanish"> Siguiente diapositiva </a>
                                </Countdown>
            </div>
        )
    }
}