import React, { Component } from 'react';
import BoxScore from './quiz/BoxScore.jsx';
import QuestionList from './quiz/QuestionList.jsx';
import Results from './quiz/Results.jsx';

export class Quiz1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q2",
            questions: [
                {
                    id: 1,
                    text: 'Being absent from school due to a family vacation would be considered',
                    choices: [
                        {
                            id: 'a',
                            text: 'Excused',
                        },
                        {
                            id: 'b',
                            text: 'Unexcused',
                        },
                        {
                            id: 'c',
                            text: 'Counted as present',
                        },
                        {
                            id: 'd',
                            text: 'Counted as two absences',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 2,
                    text: 'Being absent from school due to a suspension would be considered an unlawful absence.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 3,
                    text: 'Being absent from school due to the observance of a religious holiday would be considered a lawful absence.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 4,
                    text: 'It is considered to be an excused absence when your child is ',
                    choices: [
                        {
                            id: 'a',
                            text: 'Home with friends',
                        },
                        {
                            id: 'b',
                            text: 'Mandated to be in court',
                        },
                        {
                            id: 'c',
                            text: 'Out of state visiting family',
                        },
                        {
                            id: 'd',
                            text: 'On vacation',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 5,
                    text: 'Students who are out of school with an illness should',
                    choices: [
                        {
                            id: 'a',
                            text: 'Return to school when they are well',
                        },
                        {
                            id: 'b',
                            text: 'Provide documentation for their absence upon their return',
                        },
                        {
                            id: 'c',
                            text: 'See their teacher for make up work',
                        },
                        {
                            id: 'd',
                            text: 'All of the above',
                        }
                    ],
                    correct: 'd'
                },
                {
                    id: 6,
                    text: 'The school Principal or Pupil Personnel Worker may require a physician\'s certificate to excuse student absences which are excessive.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 7,
                    text: 'Students who are habitually truant can be placed in the custody of social services',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 8,
                    text: 'Students who are absent from school due to the death of an immediate family member are considered',
                    choices: [
                        {
                            id: 'a',
                            text: 'Excused',
                        },
                        {
                            id: 'b',
                            text: 'Unexcused',
                        },
                        {
                            id: 'c',
                            text: 'Truant',
                        },
                        {
                            id: 'd',
                            text: 'At Risk',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 9,
                    text: ' Maryland\'s Compulsory School Attendance Law mandates that all children attend school between the ages of',
                    choices: [
                        {
                            id: 'a',
                            text: '5 to 18',
                        },
                        {
                            id: 'b',
                            text: '7 to 18',
                        },
                        {
                            id: 'c',
                            text: '5 to 18',
                        },
                        {
                            id: 'd',
                            text: '6 to 16',
                        }
                    ],
                    correct: 'c'
                },
                {
                    id: 10,
                    text: 'The absences of students who have been registered as homeless will always be considered excused',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 11,
                    text: 'The following penalties can be given to parents/guardians who have been found guilty of violating Maryland\'s Compulsory School Attendance Law',
                    choices: [
                        {
                            id: 'a',
                            text: 'Incarceration',
                        },
                        {
                            id: 'b',
                            text: 'Fines',
                        },
                        {
                            id: 'c',
                            text: 'Loss of AFDC funds',
                        },
                        {
                            id: 'd',
                            text: 'All of the above',
                        }
                    ],
                    correct: 'd'
                },
                {
                    id: 12,
                    text: 'Who is legally responsible for a child to attend school regularly and on time under Maryland\'s Compulsory School Attendance Law?',
                    choices: [
                        {
                            id: 'a',
                            text: 'Parent/Guardian',
                        },
                        {
                            id: 'b',
                            text: 'Child\'s teacher',
                        },
                        {
                            id: 'c',
                            text: 'Child',
                        },
                        {
                            id: 'd',
                            text: 'All of the above',
                }
                    ],
                    correct: 'a'
                },
                {
                    id: 13,
                    text: 'Teachers are required to allow students to make up all missed assignments following an unexcused absence.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 14,
                    text: 'Maryland State Department of Education considers satisfactory attendance to be',
                    choices: [
                        {
                            id: 'a',
                            text: '85%',
                        },
                        {
                            id: 'b',
                            text: '94%',
                        },
                        {
                            id: 'c',
                            text: '90%',
                        },
                        {
                            id: 'c',
                            text: '84%',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 15,
                    text: 'Each person found guilty of violating the Maryland Compulsory Attendance Law identified having legal custody or care and control of child may be jailed up to 3 days for first conviction.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 16,
                    text: 'Each person found guilty of violating the Maryland Compulsory Attendance Law is guilty of a misdemeanor.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'a'
                },
                {
                    id: 17,
                    text: 'Each person found guilty of violating the Maryland Compulsory Attendance Law identified having legal custody or care and control of child may be jailed up to 10 days for second conviction',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                },
                {
                    id: 18,
                    text: 'Maryland school systems have the authority to file new charges against each person having legal custody or care and control of a child between 17 and 18 years old for violating the Maryland Compulsory Attendance Law.',
                    choices: [
                        {
                            id: 'a',
                            text: 'True',
                        },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                }
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}
