import React from 'react';
import { Button } from 'antd';

class Results extends React.Component {

    renderNextQuiz(){
        {
            switch (this.props.nextTest) {
                case 'Q2':
                    return <div>
                        <Button type="primary" href="/md2film">Go to Chapter 2</Button>
                    </div>;
                case 'Q2Spanish':
                    return <div>
                        <Button type="primary" href="/md2pelicula">Ir al capítulo 2</Button>
                    </div>;
                case 'Q3':
                    return <div>
                        <Button type="primary" href="/md3">Go to Chapter 3</Button>
                    </div>;
                case 'Q3Spanish':
                    return <div>
                        <Button type="primary" href="/md3spanish">Ir al capítulo 3</Button>
                    </div>;
                case 'Q4':
                    return <div>
                        <Button type="primary" href="/md4">Go to Chapter 4</Button>
                    </div>;
                case 'Q4Spanish':
                    return <div>
                        <Button type="primary" href="/md4spanish">Ir al capítulo 4</Button>
                    </div>;
                default:
                    return 'You\'re finished';
            }
        }
    }
    render() {
        var percent = (this.props.score / this.props.questions.length * 100);
        var passed = false;
        if (percent > 80) {
            var message = 'Congratulations. Your score is better than 80%. You can now move on to the next chapter.';
            var passed = true;
        } else {
            var message = 'In order for you to be able to move on to the next chapter, you must score a 90% or higher. Please click on the link below to review chapter one and re-take the quiz.';
        }
        return (
            <div className="well">
                <h4>You got {this.props.score} out of {this.props.questions.length}</h4>
                <h2>{percent}% - {message}</h2>

                
                { passed ? (
  
                    <div>
                        {this.renderNextQuiz()}
                    </div>


                ) : (
                    <div>
                    <a className="btn btn-primary" href="/">Take Test Over</a>
                    </div>
                )}
            </div>
        );
    }
}

export default Results;