import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1Spanish extends Component {

    render() {

        return (
            <div>
                <h1> Capítulo 1 - Leyes y políticas
             </h1>

                <h2> Responsabilidades del Departamento de Educación del Estado </h2>

                <ul>
                    <li> El Departamento de Educación del Estado de Maryland exige que cada sistema escolar local tenga un estándar mínimo de 94% de asistencia por período de calificación. </li>
                    <li> Cada sistema escolar local debe desarrollar una política de asistencia estudiantil que incluya una declaración general sobre el propósito y la razón fundamental del sistema escolar local para promover la asistencia regular a la escuela. </li>
                </ul>

                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md1m2spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
        )
    }
}