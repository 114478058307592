import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_5 extends Component {

    render() {

        return (
            <div>
                <h2>Impact of Truancy</h2>
                    <ul>
                        <li>Social Impact</li>

                            <li>Behavioral Impact</li>

                                <li>Economic Impact</li>
</ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m6">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}