import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_8 extends Component {

    render() {

        return (
            <div>

                    <h1>Defining Full & Partial Day</h1>
                            <li>Full Day - It is considered a full day when a student is present four or more hours during the school day.</li>

                                <li>Half Day - It is considered  a half day when a student is in attendance for at least 2 hours, but less than 4 hours of the school day</li>

                                    
                                        <h2>Documentation</h2>
                                            <ul>
                                                <li>Parents/Guardians are expected to provide their child's school with documentation of any absence by way of a doctor's note or a note drafted by the parent/guardian</li>
                                                    <li>This note is to be presented to the school upon the child's return to school from an absence.</li>
                                                        <li>Any undocumented absences are considered unexcused and unlawful</li>
                                                            <li>School Principals and/or Pupil Personnel Workers may require notes from a physician for students who have been excessively absent</li>
                                                                <li>Documented absences allow students to be eligible to make up work they missed while they were absent</li>
</ul>
            
                
                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1mreview">Review</a>
                </Countdown>
            </div>
        )
    }
}