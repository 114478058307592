import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "QFinal",
            questions: [
                {
                    id: 1,
                    text: 'Maryland public schools do not have a policy regarding bullying/harassment',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 2,
                    text: 'A student may be suspended from school for bullying another student',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 3,
                    text: 'A student whose parents are considered "illegal aliens"',
                    choices: [{
                        id: 'a',
                        text: 'Can still enroll in school',
                    },
                    {
                        id: 'b',
                        text: 'Must be in the country for 30 days before enrolling in school',
                    },
                    {
                        id: 'c',
                        text: 'Must register as homeless',
                    }, {
                        id: 'd',
                        text: 'All of the above',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 4,
                    text: 'It is appropriate to request for school officials to conduct a Functional Behavioral Assessment on a child due to his/her chronic disruptive behavior',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'a'
                }, {
                    id: 5,
                    text: 'Students who have relocated due to a financial hardship must enroll in the new zoned school',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 6,
                    text: 'There is no way for a parent to legally protect himself/herself from violating Maryland\'s Compulsory School Attendance Law when his / her child refuses to go to school ',
    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 7,
                    text: ' Children who have difficulty waking for school each morning should',
                    choices: [{
                        id: 'a',
                        text: 'Consult with their physician about a sleeping disorder',
                    },
                    {
                        id: 'b',
                        text: 'Utilize an alarm clock',
                    },
                    {
                        id: 'c',
                        text: 'Try getting to bed earlier at night',
                    },
                    {
                        id: 'd',
                        text: 'All of the above',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 8,
                    text: 'Parents who leave for work prior to their child leaving for school are not responsible for their child\'s attendance ',
    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                        {
                            id: 'b',
                            text: 'False',
                        }
                    ],
                    correct: 'b'
                }, {
                    id: 9,
                    text: 'A student who is having difficulties with her pregnancy has no option other than to attend her regular day school until the baby is born',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 10,
                    text: 'Parents/Guardians of children who have run away are encouraged to',
                    choices: [{
                        id: 'a',
                        text: 'File a report with the local authorities',
                    },
                    {
                        id: 'b',
                        text: 'Inform school personnel of the situation',
                    },
                    {
                        id: 'c',
                        text: 'Have the school withdraw the child permanently',
                    },
                    {
                        id: 'd',
                        text: 'First two answers',
                    }
                    ],
                    correct: 'd'
                }, {
                    id: 11,
                    text: 'Schools encourage parents/guardians of children who are constantly disruptive to keep them home from school on days when they are not in a good mood',
                    choices: [{
                        id: 'a',
                        text: 'True',
                    },
                    {
                        id: 'b',
                        text: 'False',
                    }
                    ],
                    correct: 'b'
                }, {
                    id: 12,
                    text: 'When a school administrator receives a written report regarding bullying in his/her school, he/she must respond within_____________',
                    choices: [{
                        id: 'a',
                        text: '1 hour',
                    },
                    {
                        id: 'b',
                        text: '1 week',
                    },
                    {
                        id: 'c',
                        text: '2 school days',
                    },
                    {
                        id: 'd',
                        text: '24 hours',
                    }
                    ],
                    correct: 'c'
                }
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}