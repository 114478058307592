import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_8Spanish extends Component {

    render() {

        return (
            <div>

                    <h1> Definici n de d a completo y parcial </h1>
                            <li> D a completo: se considera un d a completo cuando un alumno est  presente cuatro o m s horas durante el d a escolar. </li>

                                <li> Medio d a: se considera medio d a cuando un alumno asiste durante al menos 2 horas, pero menos de 4 horas del d a escolar </li>

                                    
                                        <h2> Documentaci n </h2>
                                            <ul>
                                                <li> Se espera que los padres / tutores proporcionen a la escuela de su hijo documentaci n de cualquier ausencia mediante una nota del m dico o una nota redactada por el padre / tutor </li>
                                                    <li> Esta nota debe ser presentada a la escuela cuando el ni o regrese a la escuela por una ausencia. </li>
                                                        <li> Todas las ausencias no documentadas se consideran injustificadas e ilegales </li>
                                                            <li> Los directores de escuela y / o los trabajadores de personal escolar pueden requerir notas de un m dico para estudiantes que han estado ausentes excesivamente </li>
                                                                <li> Las ausencias documentadas permiten que los estudiantes sean elegibles para recuperar el trabajo que perdieron mientras estaban ausentes </li>
</ul>
            <Countdown date={Date.now() + 15000}>
            <a className="btn btn-primary" href="/md1mreviewspanish"> Revisar </a>
            </Countdown>
            </div>
        )
    }
}