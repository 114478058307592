import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_3Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Me niego a enviar a mi hijo a la escuela regularmente debido a mi
                    necesidad frecuente de recogerlo temprano de la escuela por problemas
                    comportamiento.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore servicios de asesoramiento <br />
                        <br />
                    </ li>
                    <li> Considere los programas de tutoría <br />
                        <br />
                    </ li>
                    <li> Solicitar a los funcionarios de la escuela que realicen un comportamiento funcional
                    Evaluación (FBA) y determinar si el niño es elegible para un
  Plan de Intervención de Conducta (BIP) <br />
                        <br />
                    </ li>
                    <li> Solicitud para que el niño sea colocado en un Informe de progreso <br />

                        <br />
                    </ li>
                    <li> Solicitar a la escuela que combine al niño para explorar si él / ella
                    califica para servicios de IEP. El niño puede tener una emoción
  discapacidad <br />
                        <br />
                        Imprimir adjunto <a href="list2.html" target="_blank"> "Solicitud
    para una carta de muestra del Equipo IEP "</a>
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se niega a asistir a la escuela u obedecer mi autoridad.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore la presentación de informes de niños incorregibles en su localidad
  precinto policial <br />
                        <br />
                    </ li>
                    <li> Explore solicitando a un juez del tribunal de distrito que presente un
                    petición de emergencia para que el niño sea evaluado a nivel de salud mental
                    instalación si sospecha que él / ella está en peligro de lastimarse
  u otros <br />
                        <br />
                    </ li>
                    <li> Explora más clases de habilidades para padres <br />
                        <br />
                    </ li>
                    <li> Acompañe al niño a la escuela todos los días y asegúrese de que el personal de la escuela
                    los miembros observan que dejas al niño
  </ li>
                </ul>

<Countdown date={Date.now() + 15000}>
<a className="btn btn-primary" href="/md4m4">Next Slide</a>
</Countdown>
            </div>
        )
    }
}