import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Estad sticas asombrosas </h2>
                    <ul>
                        <li> Cada 26 segundos que un alumno abandona la escuela </li>
                            <li> Cada a o, m s de 1 mill n de ni os abandonan la escuela </li>
                                <li> 8 de cada 10 desertores terminan en prisi n </li>
                                    <li> Estados Unidos gasta aproximadamente 40 mil millones de d lares cada a o en encarcelamiento de prisioneros </li>
                                        <li> Un desertor de la escuela secundaria ganar  1 mill n de d lares menos durante su vida que un graduado universitario </li>
                                            <li> Los desertores de la escuela secundaria les cuestan a los contribuyentes miles de millones de d lares en salarios perdidos, bienestar, desempleo y gastos relacionados con la delincuencia </li>
                                                <li> Fuente: Fundaci n Mattie C. Stewart, Dra. Shelley Stewart, 2009 </li>
                    </ul>
                    <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md2m2spanish"> Siguiente diapositiva </a>
                    </Countdown>
            </div>
        )
    }
}