import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_ReviewSpanish extends Component {

    render() {

        return (
<div>
                <h1> Cap tulo 1 - Leyes y pol ticas - Revisar </h1>

<h2> Responsabilidades del Departamento de Educaci n del Estado </h2>

<ul>
    <li> El Departamento de Educaci n del Estado de Maryland exige que cada sistema escolar local tenga un est ndar m nimo de 94% de asistencia por per odo de calificaci n. </li>
    <li> Cada sistema escolar local debe desarrollar una pol tica de asistencia estudiantil que incluya una declaraci n general sobre el prop sito y la raz n fundamental del sistema escolar local para promover la asistencia regular a la escuela. </li>
</ul>

<h1> Ley de asistencia escolar obligatoria de Maryland </h1>
                    <p>
                    El Art culo 7-301 del C digo Anotado de Maryland requiere que todos los ni os entre las edades de 5 y 16 a os asistan regularmente a la escuela p blica durante el a o escolar acad mico, a menos que est n inscritos en la escuela privada o en el hogar. </p>

               <h1> Deber de padre / tutor </h1>
                <p> Cada persona que tiene un hijo entre las edades de 5 y 16 a os, bajo su custodia, es responsable de asegurarse de que asista a la escuela regularmente. </p>
   
                <h1> Penalizaciones </h1>
                    <ul>
                        <li> Cualquier padre / tutor que no env a regularmente a un ni o a la escuela est  violando la Ley de Asistencia Escolar Obligatoria de Maryland. </li>
                            <li> Como resultado, el distrito escolar local puede presentar cargos en el Tribunal de Distrito de Maryland. </li>
                                <li> Cualquier padre o tutor declarado culpable de violar la Ley de Asistencia Escolar Obligatoria de Maryland estar a sujeto a una multa que no exceder  $ 50 por d a de ausencia ilegal, el encarcelamiento no debe exceder los 10 d as, o ambos, por la primera condena. </li>
                                    <li> Cualquier padre / tutor que viole la Ley de Asistencia Escolar Obligatoria de Maryland por una segunda condena o posterior est  sujeta a una multa que no exceder  $ 100 por d a por ausencia ilegal, prisi n que no exceda los 30 d as, o ambas. Adem s, los hijos de este padre / tutor pueden ser puestos bajo la custodia del Departamento de Servicios Sociales luego de su encarcelamiento. </li>
                                        <li> AFDC: un padre / tutor que recibe Asistencia P blica est  sujeto a que se le reduzcan los beneficios en $ 25 por mes si un ni o bajo su custodia no asiste a la escuela el 80% del tiempo. </li>
                    </ul>

                     <h1> Definici n de absentismo escolar </h1>
                        <ul>
                            <li> Absentismo escolar: cualquier ausencia ilegal de un ni o de la escuela sin el conocimiento o permiso del padre / tutor. </li>
                                <li> Ausentismo habitual: cualquier alumno que falte a la escuela por m s del 20 por ciento de cualquier per odo de calificaciones de un a o escolar. </li>
                        </ul>

                 <h1> Definici n de ausencias legales e ilegales </h1>
                        <h2> Ausencias legales: las ausencias de la escuela son legales solo bajo las siguientes condiciones: </h2>
                            <ul>
                                <li> Muerte en familia </li>
                                    <li> Enfermedad del ni o </li>
                                        <li> Invocaciones judiciales </li>
                                            <li> Condiciones clim ticas peligrosas </li>
                                                <li> Trabajo aprobado </li>
                                                    <li> Fiesta religiosa </li>
                                                        <li> Emergencia estatal </li>
                                                            <li> Otra emergencia </li>
                                                                <li> Exclusi n de salud </li>
                                                                    <li> Suspensi n </li>
                                                                        <li> Falta de transporte autorizado </li>
                </ul>

                 <h1> Ausencias ilegales </h1>
                    <ul>
                        <li> Ausentismo injustificado y todas las dem s ausencias no espec ficamente enumeradas como legales. </li>
                    </ul>
                                <b> Ejemplos de ausencias ilegales: </b>
                                    <ul>
                                        <li> Padre / tutor que se lleva a un ni o de vacaciones </li>
                                            <li> Estudiante que se queda en casa para cuidar a un hermano menor o a un padre. </li>
                                                <li> El estudiante se qued  dormido </li>
                                                    <li> Estudiante agotado por estar despierto toda la noche jugando consola de juegos o viendo televisi n </li>
                                                        <li> Cumplea os del estudiante </li>
                                                            <li> El empleo de los estudiantes es durante el horario escolar </li>
                                                                <li> El estudiante no tiene hogar y, por lo tanto, no puede ir a la escuela </li>
</ul>

                    <h1> Definici n de d a completo y parcial </h1>
                            <li> D a completo: se considera un d a completo cuando un alumno est  presente cuatro o m s horas durante el d a escolar. </li>

                                <li> Medio d a: se considera medio d a cuando un alumno asiste durante al menos 2 horas, pero menos de 4 horas del d a escolar </li>

                                    
                                        <h2> Documentaci n </h2>
                                            <ul>
                                                <li> Se espera que los padres / tutores proporcionen a la escuela de su hijo documentaci n de cualquier ausencia mediante una nota del m dico o una nota redactada por el padre / tutor </li>
                                                    <li> Esta nota debe ser presentada a la escuela cuando el ni o regrese a la escuela por una ausencia. </li>
                                                        <li> Todas las ausencias no documentadas se consideran injustificadas e ilegales </li>
                                                            <li> Los directores de escuela y / o los trabajadores de personal escolar pueden requerir notas de un m dico para estudiantes que han estado ausentes excesivamente </li>
                                                                <li> Las ausencias documentadas permiten que los estudiantes sean elegibles para recuperar el trabajo que perdieron mientras estaban ausentes </li>
</ul>

<Countdown date={Date.now() + 120000}>
<a className="btn btn-primary" href="/quiz1spanish"> Iniciar prueba </a>
</Countdown>
            </div>
        )
    }
}