import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_ReviewSpanish extends Component {

    render() {

        return (
            <div>
                <h2> Capítulo 3 Revisión </ h2>
                <p>Se definirán los siguientes términos para crear un mejor comprensión del material cubierto en el siguiente capítulo. Estos términos son cruciales para el entorno de aprendizaje de muchos de nuestros estudiantes en todo el país.
</ p>
                <ul>
                    <li> Declaración jurada de cuidado de parentesco informal
  </ li>
                    <li> Servicios de hogar y hospital
  </ li>
                    <li> Plan de educación individualizado (IEP)
  </ li>
                    <li> Plan 504
  </ li>
                    <li> Evaluación del comportamiento funcional (FBA)
  </ li>
                    <li> Plan de Intervención de Conducta (BIP)
  </ li>
                    <li> Intimidación
  </ li>
                    <li> Escuela en el hogar
  </ li>
                    <li> Ley de asistencia a personas sin hogar McKinney-Vento
  </ li>
                </ ul>
                <p>
                    <strong> Declaración jurada informal sobre el cuidado de parientes </ strong>
                </ p>
                <ul>
                    <li>
                        <strong> Cuidado de parentesco informal: </ strong> se refiere a una vida
                        acuerdo en el que un pariente de un niño, que no está en el
                        cuidado, custodia o tutela del departamento local de
                        servicios sociales, provee cuidado y custodia del niño
                        debido a dificultades familiares graves, las 24 horas del día, siete días a
                        semana. Esto solo se aplica si el padre / tutor que enfrenta al
                        dificultad es un residente de Maryland.
  </ li>
                </ ul>
                <p>
                    <strong> Elegibilidad de dificultades familiares </ strong>
                </ p>
                <ul>
                    <li> Muerte del padre o tutor legal del niño.
  </ li>
                    <li> Enfermedad grave del padre o tutor legal.
  </ li>
                    <li> Drogadicción del padre o tutor legal
  </ li>
                    <li> Encarcelamiento de padre o tutor legal
  </ li>
                    <li> Abandono del niño
  </ li>
                    <li> Asignación de padre o tutor legal a militares activos
  deber. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Documentos solicitados para determinar el estado de aprobación de
  Declaración jurada de cuidado de parentesco informal: </ strong>
                </ p>
                <ul>
                    <li> Copia del certificado de defunción del padre / tutor legal.
  </ li>
                    <li> Documentación médica de una enfermedad grave del médico de
                    padre / tutor legal
  </ li>
                    <li> Documentación del centro de detención donde los padres / legal
                    guardian está encarcelado.
  </ li>
                    <li> Otra documentación de varias agencias, como las redes sociales
                    servicios, tribunales, militares, etc. que verifiquen los padres / legal
                    dificultad guardiana.
  </ li>
                </ ul>
                <p>
                    <strong> Determinación de la ubicación en la escuela: </ strong>
                </ p>
                <ul>
                    <li> El familiar de un niño dentro del 5 ° grado de sangre o
                    el matrimonio debe brindar cuidado dentro de la zona de asistencia escolar en
                    que él / ella actualmente reside.
  </ li>
                </ ul>
                <p>
                    <strong> Obtención de la declaración jurada de cuidado familiar informal: </ strong>
                </ p>
                <ul>
                    <li> Consejo de educación local
  </ li>
                    <li> Departamento de Servicios Sociales
  </ li>
                    <li> Oficina local de envejecimiento
  </ li>
                </ ul>
                <p>
                    <strong> Servicios de atención domiciliaria y hospitalaria </ strong>
                </ p>
                <ul>
                    <li>
                        <strong> Servicios de atención domiciliaria y hospitalaria: </ strong> el código de
                        El Reglamento de Maryland (COMAR) 13A.03.05.01 establece: "Estos
                        reglamentos que establecen un requisito mínimo, se aplican a
                        la provisión de servicios de instrucción para la escuela pública
                        estudiantes que no pueden participar en su escuela de
                        inscripción debido a una condición física o emocional ".
  </ li>
                </ ul>
                <p>
                    <strong> Cómo obtener una aplicación de hogar y hospital: </ strong>
                </ p>
                <ul>
                    <li> Comuníquese con el coordinador de hogar y hospital de su
                    distrito escolar local. Revise la Lista Maestra adjunta para ubicar el
                    coordinador local de hogar y hospital para su escuela respectiva
  distrito. <br />
                        <br />
                        <a href="list1.html" target="_blank"> Haga clic aquí para ver una lista
    de las oficinas de hogares y hospitales de los condados de Maryland </a>
                    </ li>
                </ ul>
                <p>
                    <strong> Plan de educación individualizado (IEP) </ strong>
                </ p>
                <ul>
                    <li> Un Plan de educación individualizado (IEP) es un plan creado por
                    una escuela individual en un esfuerzo por cumplir con el único educativo
                    necesidades de un niño que tiene una discapacidad, según lo definido por el
                    regulaciones. El IEP está destinado a ayudar a los niños a alcanzar
                    objetivos educativos más fáciles de lo que lo harían y se utiliza como
                    un recurso por maestros y proveedores de servicios relacionados.
  </ li>
                </ ul>
                <p>
                    <strong> Plan 504 </ strong>
                </ p>
                <ul>
                    <li> Un plan 504 es un plan educativo creado por
                    escuelas para servir a estudiantes con un impedimento físico o mental
                    que limita sustancialmente una o más actividades principales de la vida.
                    Esto puede incluir impedimentos físicos, enfermedades, transmisibles
                    enfermedades, condiciones crónicas y problemas de aprendizaje. El plan 504
                    describirá las modificaciones y adaptaciones que serán
                    necesario para que estos estudiantes tengan la oportunidad de actuar en
                    el mismo nivel que sus compañeros. Estos alojamientos podrían incluir
                    cosas tales como una rampa para sillas de ruedas, menús especiales para el almuerzo o el hogar
                    instrucción.
  </ li>
                </ ul>
                <p>
                    <strong> Evaluación del comportamiento funcional (FBA) </ strong>
                </ p>
                <ul>
                    <li> Una evaluación de comportamiento funcional (FBA) es una solución de problemas
                    proceso para abordar el comportamiento problemático del estudiante. Desarrolla
                    técnicas y estrategias para identificar los propósitos de
                    comportamientos para ayudar a los equipos del IEP a seleccionar intervenciones directamente
                    abordar los problemas de comportamiento. Un FBA debe estar integrado en
                    el proceso de desarrollo, revisión y revisión de un estudiante
                    IEP. Este proceso debe incluir aportaciones del alumno,
                    padre / tutor y personal de la escuela
  </ li>
                    <li> Una FBA se enfoca en identificar aspectos sociales significativos y específicos,
                    factores afectivos, cognitivos y / o ambientales asociados
                    con la ocurrencia (y la no ocurrencia) de comportamientos específicos de
                    un niño individual.
  </ li>
                </ ul>
                <p>
                    <strong> Plan de intervención de comportamiento (BIP) </ strong>
                </ p>
                <ul>
                    <li> Un plan de intervención de comportamiento (BIP) detalla cómo un equipo de IEP
                    mejorará el comportamiento difícil que es una barrera para el niño
                    éxito académico. Un BIP es un documento que describe cómo
                    El equipo de IEP ayudará a un niño a mejorar su comportamiento en instancias
                    cuando él / ella no puede enfocarse, no completa el trabajo, interrumpe el
                    aula, y / o constantemente está en problemas. Un BIP está en orden
                    cuando el comportamiento de un niño interrumpe el aula y significativamente
                    interrumpe su educación.
  </ li>
                </ ul>
                <p>
                    <strong> Intimidación </ strong>
                </ p>
                <ul>
                    <li> La intimidación está presente cuando un alumno está expuesto a repeticiones,
                    acciones negativas por parte de una o más personas sobre
                    tiempo, y él o ella tiene dificultad para defenderse.
  </ li>
                    <li> Implica un comportamiento agresivo asociado con el no deseado,
                    acciones negativas; un patrón de comportamiento repetido a lo largo del tiempo; y
                    un desequilibrio de poder o fuerza.
  </ li>
                </ ul>
                <p>
                    <strong> Tipos de intimidación </ strong>
                </ p>
                <ul>
                    <li> Acoso cibernético (a través del teléfono celular o Internet)
  </ li>
                    <li> Ser amenazado o ser obligado a hacer actos
  </ li>
                    <li> Intimidación verbal (incluidos comentarios derogatorios y nombre
                    vocación)
  </ li>
                    <li> Bullying a través de mentiras y la propagación de rumores
  </ li>
                    <li> Bullying a través de la exclusión social o el aislamiento
  </ li>
                    <li> Acoso racial
  </ li>
                    <li> Tener dinero u otros artículos personales tomados o dañados
  </ li>
                    <li> Intimidación física (incluido golpear, patear, empujar y
                    escupir)
  </ li>
                    <li> Acoso sexual
  </ li>
                </ ul>
                <p>
                    <strong> Educación en el hogar </ strong>
                </ p>
                <ul>
                    <li> Cualquier padre / tutor puede instruir a un niño en casa bajo el
                    supervisión del sistema escolar local o mediante correspondencia
  cursos bajo la supervisión de una escuela no pública. <br />
                        <br />
                    </ li>
                    <li> Cualquier padre / tutor que desee educar en casa a un niño debe
                    contacte al coordinador de educación en el hogar para la escuela local
                    sistema en el cual el niño reside. El padre / tutor debe
                    inicialmente complete y firme un "Formulario de notificación de escolarización en el hogar"
                    para indicar su intención de educar a un niño en casa y cumplir
                    con las regulaciones de educación en el hogar. Cada año a partir de entonces, el
                    el padre / tutor debe verificar la continuación de la educación en el hogar
                    con el superintendente de escuelas locales o el supervisor
  escuela / institución no pública. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Ley de asistencia a personas sin hogar McKinney-Vento </ strong>
                </ p>
                <p>
                    Esta ley se aplica a cualquier persona que:
</ p>
                <ul>
                    <li> Carece de una residencia nocturna regular, fija y adecuada (sub
                    vivienda estándar)
  </ li>
                    <li> Es compartir viviendas debido a dificultades económicas (duplicado)
  </ li>
                    <li> Está viviendo en un refugio, hotel o motel
  </ li>
                    <li> Está viviendo en un lugar público no diseñado para dormir (automóviles,
                    parques)
  </ li>
                    <li> Es un joven no acompañado
  </ li>
                    <li> Es un niño o joven en espera de colocación de cuidado de crianza
  </ li>
                    <li> Es un niño o joven abandonado en un hospital
  </ li>
                    <li> Es un niño migrante que califica bajo cualquiera de los anteriores
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}