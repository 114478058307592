import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_3 extends Component {

    render() {

        return (
            <div>
                <h2>Family Dropout Factors</h2>
                    <ul>
                        <li>Lack of parental supervision or guidance</li>
                            <li>Substance abuse</li>
                                <li>Domestic violence</li>
                                    <li>Unavailable to provide childcare for grandchild of teenage parent</li>
                                        <li>Child neglect</li>
                                            <li>Student providing care to family members with failing health</li>
                                                <li>Family history of dropping out of school</li>
                                                </ul>
                                                
                                                <Countdown date={Date.now() + 15000}>
                                                <a className="btn btn-primary" href="/md2m4">Next Slide</a>                   
            </Countdown>
            </div>
        )
    }
}