import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_2 extends Component {

    render() {

        return (
            <div>
                <h1>Maryland Compulsory Attendance Law — Article 7-301</h1>
                    <p>
                    Definition: Each person who has legal custody or care and control of a child who is between 5 and 18 years old shall see that the child attends school or receives an alternative education instruction approved by the Maryland State Department of Education</p>

                    <p>Any person who has legal custody or care and control of a child who is 5 years old and under 18
is guilty of a misdemeanor if they neglect to send child to school daily and the majority of absences were coded unexcused by local school system.
</p>
<ul>
    <li>First conviction is subject to a fine not to exceed $50 dollars per day of unlawful absence or imprisonment not to exceed 3 days or both.</li>
    <li>Second or subsequent conviction is subject to a fine not to exceed $100 per day of unlawful absences or imprisonment not to exceed 5 days, or both.</li>
</ul>
                

                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}