import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_8Spanish extends Component {

    render() {

        return (
            <div>
<h2> Home Schooling </ h2>
                <ul>
                    <li> Cualquier padre / tutor puede instruir a un niño en casa bajo el
                    supervisión del sistema escolar local o mediante correspondencia
  cursos bajo la supervisión de una escuela no pública. <br />
                        <br />
                    </ li>
                    <li> Cualquier padre / tutor que desee educar en casa a un niño debe
                    contacte al coordinador de educación en el hogar para la escuela local
                    sistema en el cual el niño reside. El padre / tutor debe
                    inicialmente complete y firme un "Formulario de notificación de escolarización en el hogar"
                    para indicar su intención de educar a un niño en casa y cumplir
                    con las regulaciones de educación en el hogar. Cada año a partir de entonces, el
                    el padre / tutor debe verificar la continuación de la educación en el hogar
                    con el superintendente de escuelas locales o el supervisor
  escuela / institución no pública. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                        <strong> Cómo obtener una educación en el hogar
  Aplicación: </ strong>
                </ p>
                <ul>
                    <li>
                               Por favor, póngase en contacto con el coordinador de educación en el hogar en su localidad
                                Distrito escolar. Revise la Lista Maestra adjunta para ubicar el
                                Coordinador local de educación en el hogar para su escuela respectiva
    distrito. <br />
                                <br />
                    </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m9">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}