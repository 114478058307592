import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_8 extends Component {

    render() {

        return (
            <div>
                <h2>Home Schooling</h2>
                <ul>
                    <li>Any parent/guardian may instruct a child at home under the
                    supervision of the local school system or through correspondence
  courses under the supervision of a nonpublic school.<br />
                        <br />
                    </li>
                    <li>Any parent/guardian who desires to home school a child should
                    contact the home schooling coordinator for the local school
                    system in which the child resides. The parent/guardian must
                    initially complete and sign a "Home Schooling Notification Form"
                    to indicate his/her intent to home school a child and to comply
                    with the home schooling regulations. Each year thereafter, the
                    parent/guardian must verify the continuation of home schooling
                    with the local superintendent of schools or the supervising
  nonpublic school/institution.<br />
                        <br />
                    </li>
                </ul>
                <p>
                        <strong>How to obtain a Home Schooling
  Application:</strong>
                </p>
                <ul>
                    <li>
                               Please contact the Home Schooling Coordinator in your local
                                school district. Review attached Master List to locate the
                                local Home Schooling Coordinator for your respective school
    district.<br />
                                <br />
                    </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m9">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}