import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_6 extends Component {

    render() {

        return (
            <div>
                <h2>Social Impact.</h2>
                    <ul>
                        <li>Disengagement from peers and school community</li>
                            <li>Isolation from extracurricular activities</li>
                                <li>School removal due to delinquent behavior</li>
                                    <li>Dropout</li>
                                        <li>Complacency with life without school</li>
                                            <li>Removal of school support system </li>

</ul>
<Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md2m7">Next Slide</a>
                    </Countdown>
            </div>
        )
    }
}