import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_2Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Es imposible para mi hijo asistir a la escuela regularmente debido
  a una enfermedad crónica o discapacidad física. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> El alumno puede ser elegible para un programa escolar modificado <br />
                        <br />
                    </ li>
                    <li> Explore si el niño es elegible para un Plan 504 <br />
                        <br />
                    </ li>
                    <li> Explore Home & amp; Servicios hospitalarios <br />
                        <br />
                    </ li>
                    <li> Inscríbase en un programa de educación en el hogar aprobado
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo se niega a asistir a la escuela regularmente debido a él / ella
  luchando académicamente. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Conferencia de docentes de configuración <br />
                        <br />
                    </ li>
                    <li> Explora servicios de tutoría gratis <br />
                        <br />
                    </ li>
                    <li> Solicitud para que su hijo se asocie para explorar si él / ella
                    califica para servicios educativos adicionales. El niño puede tener un
  discapacidad educativa que califica para servicios de IEP <br />
                        <br />
                        Imprimir adjunto <a href="list2.html" target="_blank"> "Solicitud
    para una carta de muestra del Equipo IEP "</a>
  </ li>
                </ ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}