import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_5Spanish extends Component {

    render() {

        return (
            <div>
                <h1> Definici n de absentismo escolar </h1>
                <ul>
                    <li> Absentismo escolar: cualquier ausencia ilegal de un ni o de la escuela sin el conocimiento o permiso del padre / tutor. </li>
                    <li> Ausentismo habitual: cualquier alumno que falte a la escuela por m s del 20 por ciento de cualquier per odo de calificaciones de un a o escolar. </li>
                </ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md1m6spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>)
    }
}