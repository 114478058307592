import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_5 extends Component {

    render() {

        return (
            <div>
                <h2>504 Plan</h2>
                <ul>
                    <li>A 504 plan is an educational plan created by individual schools to service students with a physical or mental impairment which substantially limits one or more major life activities. This can include physical impairments, illnesses, communicable diseases, chronic conditions, and learning problems. The 504 plan will outline the modifications and accommodations that will be needed for these students to have an opportunity to perform at the same level as their peers. These accommodations could include such things as a wheelchair ramp, special lunch menus, or home instruction. </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m6">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}