import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_4Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Plan de educación individualizado (IEP) </ h2>
                <ul>
                    <li> Un Plan de Educación Individualizado (IEP) es un plan creado por una escuela individual en un esfuerzo por satisfacer las necesidades educativas únicas de un niño que tiene una discapacidad, según lo definen las reglamentaciones federales. El IEP está destinado a ayudar a los niños a alcanzar los objetivos educativos más fácilmente de lo que lo harían de otra manera y los profesores y proveedores de servicios relacionados lo usan como recurso. </li>
                </ ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m5">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}