import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_3Spanish extends Component {

    render() {

        return (
            <div>
                <h1> Deber de padre / tutor </h1>
                <p> Cada persona que tiene un hijo entre las edades de 5 y 18 a os, bajo su custodia, es responsable de asegurarse de que asista a la escuela regularmente. </p>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md1m4spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
                            )
    }
}