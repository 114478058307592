import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_3 extends Component {

    render() {

        return (
            <div>
                <h1>Duty of Parent/Guardian</h1>          
                <p>Each person who has a child between the ages of 5 and 18 years old, under his/her custody, is held responsible for making sure that child attends school regularly.</p>
            
                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m4">Next Slide</a>
                </Countdown>
            </div>
                            )
    }
}