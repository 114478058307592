import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_8Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Marcador de posici n </h2>


<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2mreviewspanish"> Siguiente diapositiva </a>
            </Countdown>
            </div>
        )
    }
}