import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_7Spanish extends Component {

    render() {

        return (
            <div>
                <h1> Ausencias ilegales </h1>
                    <ul>
                        <li> Ausentismo injustificado y todas las dem s ausencias no espec ficamente enumeradas como legales. </li>
                    </ul>
                                <b> Ejemplos de ausencias ilegales: </b>
                                    <ul>
                                        <li> Padre / tutor que se lleva a un ni o de vacaciones </li>
                                            <li> Estudiante que se queda en casa para cuidar a un hermano menor o a un padre. </li>
                                                <li> El estudiante se qued  dormido </li>
                                                    <li> Estudiante agotado por estar despierto toda la noche jugando consola de juegos o viendo televisi n </li>
                                                        <li> Cumplea os del estudiante </li>
                                                            <li> El empleo de los estudiantes es durante el horario escolar </li>
                                                                <li> El estudiante no tiene hogar y, por lo tanto, no puede ir a la escuela </li>
</ul>
            
            <Countdown date={Date.now() + 15000}>
            <a className="btn btn-primary" href="/md1m8spanish"> Siguiente diapositiva </a>
            </Countdown>
            </div>)
    }
}