import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_4Spanish extends Component {

    render() {

        return (
            <div>
<h1> Penalizaciones </h1>
<ul>
<li> Cualquier padre / tutor que no env a regularmente a un ni o a la escuela est  violando la Ley de Asistencia Escolar Obligatoria de Maryland. </li>
<li> Como resultado, el distrito escolar local puede presentar cargos en el Tribunal de Distrito de Maryland. </li>
<li> Cualquier padre o tutor declarado culpable de violar la Ley de Asistencia Escolar Obligatoria de Maryland estar a sujeto a una multa que no exceder  $ 50 por d a de ausencia ilegal, el encarcelamiento no debe exceder los 10 d as, o ambos, por la primera condena. </li>
<li> Cualquier padre / tutor que viole la Ley de Asistencia Escolar Obligatoria de Maryland por una segunda condena o posterior est  sujeta a una multa que no exceder  $ 100 por d a por ausencia ilegal, prisi n que no exceda los 30 d as, o ambas. Adem s, los hijos de este padre / tutor pueden ser puestos bajo la custodia del Departamento de Servicios Sociales luego de su encarcelamiento. </li>
<li> AFDC: un padre / tutor que recibe Asistencia P blica est  sujeto a que se le reduzcan los beneficios en $ 25 por mes si un ni o bajo su custodia no asiste a la escuela el 80% del tiempo. </li>
</ul>
<Countdown date={Date.now() + 15000}>
<a className="btn btn-primary" href="/md1m5spanish"> Siguiente diapositiva </a>
</Countdown>
</div>)
    }
}