import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
export default class MD3_4 extends Component {

    render() {

        return (
            <div>
                <h2>Individualized Education Plan (IEP)</h2>
                <ul>
                    <li>An Individualized Education Plan (IEP) is a plan created by an individual school in an effort to meet the unique educational needs of a child who has a disability, as defined by federal regulations. The IEP is intended to aid children in reaching educational goals easier than they otherwise would and is used as a resource by teachers and related service providers.</li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m5">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}