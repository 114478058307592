import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1 extends Component {

    render() {

        return (
            <div>
                <h1>Chapter 1 - Laws and Policies
            </h1>

                <h2>State Department of Education Accountabilities</h2>

                <ul>
                    <li>The Maryland State Department of Education mandates each local school system to have the minimal standard of 94% attendance rate per marking period.</li>
                    <li>Each local school system must develop a student attendance policy which includes a general statement regarding the local school system's purpose and rationale for promoting regular school attendance.</li>
                </ul>

                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}