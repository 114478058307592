import React, { Component } from 'react';

class Callback extends Component {
    render() {

    return (
            <div>
                <h2>Loading...</h2>
            </div>
        );
    }
}

export default Callback;