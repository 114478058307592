import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_9Spanish extends Component {

    render() {

        return (
            <div>
                
                    <h2> Ley de asistencia a personas sin hogar McKinney-Vento </ h2>

                <p>
                    Esta ley se aplica a cualquier persona que:
</ p>
                <ul>
                    <li> Carece de una residencia nocturna regular, fija y adecuada (sub
  vivienda estándar) <br />
                        <br />
                    </ li>
                    <li> Compartir viviendas debido a problemas económicos
  (duplicado) <br />
                        <br />
                    </ li>
                    <li> Está viviendo en un refugio, hotel o motel <br />
                        <br />
                    </ li>
                    <li> Está viviendo en un lugar público no diseñado para dormir (automóviles,
  parques) <br />
                        <br />
                    </ li>
                    <li> Es un joven no acompañado <br />
                        <br />
                    </ li>
                    <li> Es un niño o joven en espera de colocación en hogares de guarda <br />
                        <br />
                    </ li>
                    <li> Es un niño o un joven abandonado en un hospital <br />
                        <br />
                    </ li>
                    <li> Es un niño migrante que califica bajo cualquiera de los anteriores
  </ li>
                </ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3mreview">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}