import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Me niego a enviar a mi hijo a la escuela, debido a que él / ella regularmente
  siendo intimidado. <br />
                        <br />
                    </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Informar al administrador de la escuela para abordar el acoso
  preocupaciones <br />
                        <br />
                    </ li>
                    <li> Solicitar una conferencia para padres <br />
                        <br />
                    </ li>
                    <li> Intento que un consejero de orientación escolar participe para trabajar
                    con ambos estudiantes involucrados en el conflicto. La guía de la escuela
                    consejero puede intentar resolver el conflicto a través de la mediación
  y / o resolución de conflictos <br />
                        <br />
                    </ li>
                    <li> Informe de hostigamiento / acoso escolar en la escuela local
  </ li>
                </ ul>
                <p>
                    <strong> Acciones que las escuelas pueden tomar en respuesta a las denuncias de
  bullying </ strong> <br />
                </ p>
                <ul>
                    <li> Los administradores escolares deben investigar los informes de intimidación
  dentro de 2 días escolares de recibir un <a href="example"> "BULLYING,
  FORMULARIO DE NOTIFICACIÓN DE HOSTIGAMIENTO O INTIMIDACIÓN "</a> (documento de Word) <br />
                        <br />
                    </ li>
                    <li> Mediación entre pares <br />
                        <br />
                    </ li>
                    <li> Reestructuración de horarios de clase <br />
                        <br />
                    </ li>
                    <li> Conferencias de padres obligatorias con todas las partes involucradas <br />
                        <br />
                    </ li>
                    <li> Suspensión local <br />
                        <br />
                    </ li>
                    <li> Suspensión a la Junta de Educación <br />
                        <br />
                    </ li>
                    <li> Participación del departamento de policía local <br />
                        <br />
                    </ li>
                    <li> Otros
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}