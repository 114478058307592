import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_5 extends Component {

    render() {

        return (
            <div>
                    <h1>Defining Truancy</h1>
                        <ul>
                            <li>Truancy - any unlawful absence of a child from school without the knowledge or permission of the parent/guardian.</li>
                                <li>Habitual Truant - any student who is truant from school for more than 20 percent of any grading period of a school year.</li>
                        </ul>
            
                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m6">Next Slide</a>
                </Countdown>
            </div>)
    }
}