import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>I refuse to send my child to school, due to him/her regularly
  being bullied.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Inform school administrator to address bullying
  concerns<br />
                        <br />
                    </li>
                    <li>Request a Parent Conference<br />
                        <br />
                    </li>
                    <li>Attempt to get a school guidance counselor involved to work
                    with both students involved in the conflict. The school guidance
                    counselor may attempt to resolve the conflict through mediation
  and/or conflict resolution<br />
                        <br />
                    </li>
                    <li>File Bullying/Harassment Report at local school
  </li>
                </ul>
                <p>
                    <strong>Actions schools may take in response to allegations of
  bullying</strong><br />
                </p>
                <ul>
                    <li>School administrators must investigate reports of bullying
  within 2 school days of receiving a completed <a href=
                            "Harassment_or_Intimidation_Reporting_Form.docx">"BULLYING,
  HARASSMENT OR INTIMIDATION REPORTING FORM"</a> (Word doc)<br />
                        <br />
                    </li>
                    <li>Peer Mediation<br />
                        <br />
                    </li>
                    <li>Restructuring of class schedules<br />
                        <br />
                    </li>
                    <li>Required parent conferences with all involved parties<br />
                        <br />
                    </li>
                    <li>Local suspension<br />
                        <br />
                    </li>
                    <li>Suspension to the Board of Education<br />
                        <br />
                    </li>
                    <li>Involvement of local police department<br />
                        <br />
                    </li>
                    <li>Other
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}