import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_7 extends Component {

    render() {

        return (
            <div>
                <h2>Bullying</h2>
                <ul>
                    <li>Bullying is present when a student is exposed to repeated,
                    negative actions on the part of one or more other persons over
                    time, and he or she has difficulty defending himself or
  herself.<br />
                        <br />
                    </li>
                    <li>It involves aggressive behavior associated with unwanted,
                    negative actions; a pattern of behavior repeated over time; and
                    an imbalance of power or strength.
  </li>
                </ul>
                <p>
                    <br />
                    
                        <strong>Types of Bullying</strong>
                </p>
                <ul>
                    <li>
                        
                                Cyber bullying (via cell phone or Internet)<br />
                                <br />
                    </li>
                    <li>
                       Being threatened or being forced to do acts<br />
                                <br />
                    </li>
                    <li>
                               Verbal bullying (including derogatory comments and name
    calling)<br />
                                <br />
                    </li>
                    <li>
                               Bullying through lies and the spread of rumors<br />
                                <br />
                    </li>
                    <li>
                              Bullying through social exclusion or isolation<br />
                                <br />
                    </li>
                    <li>
                               Racial bullying<br />
                                <br />
                    </li>
                    <li>
                               Having money or other personal items taken or damaged<br />
                                <br />
                    </li>
                    <li>
                                Physical bullying (including hitting, kicking, shoving, and
    spitting)<br />
                                <br />
                    </li>
                    <li>
                               Sexual bullying
                    </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m8">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}