import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_4 extends Component {

    render() {

        return (
            <div>
                <h2>School Dropout Factors</h2>
                    <ul>
                        <li>Lack of effective and consistently applied attendance policies</li>
                            <li>Poor record-keeping, making truancy difficult to spot</li>
                                <li>Parents/guardians not notified of absences daily</li>
                                    <li>"Push Out" policies consisting of suspensions and automatic failing grades as consequences for truancy</li>
                                        <li>Unsafe school environment which consists of ineffective discipline policies resulting in high incidence of bullying.</li>
                                        </ul>
                                        <Countdown date={Date.now() + 15000}>
                                        <a className="btn btn-primary" href="/md2m5">Next Slide</a> 
                                        </Countdown>       
                                        
            </div>
        )
    }
}