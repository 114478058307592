import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_4Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Factores de abandono escolar </h2>
                <ul>
                    <li> Falta de pol ticas de asistencia efectivas y aplicadas consistentemente </li>
                    <li> Pobre mantenimiento de registros, lo que hace que el ausentismo sea dif cil de detectar </li>
                    <li> Los padres / tutores no son notificados de las ausencias diarias </li>
                    <li> Pol ticas de "expulsi n" que consisten en suspensiones y calificaciones reprobables autom ticas como consecuencia del absentismo escolar </li>
                    <li> Ambiente escolar inseguro que consiste en pol ticas de disciplina ineficaces que resultan en una alta incidencia de bullying. </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m5spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
        )
    }
}