import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_7Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Impacto econ mico </h2>
                    <ul>
                        <li> Las escuelas pierden fondos educativos estatales debido a la alta tasa de deserci n </li>
                            <li> Los abandonos tienen una mayor probabilidad de estar desempleados </li>
                                <li> Los abandonos tienen una mayor probabilidad de recibir efectivo o asistencia m dica del gobierno estatal. </li>
                                    <li> Los desertores tienen una probabilidad m s alta de ser encarcelados y los contribuyentes estatales defraudan el costo de la vivienda. </li>
                                        <li> Los abandonos tienen una mayor probabilidad de vivir en la pobreza. </li>
                                            <li> Mientras m s educaci n alcanza un individuo, m s potencial tiene para aumentar su salario potencial. </li>
                                                <li> Ver gr fico de salario econ mico (p gina siguiente) </li>
                                                </ul>
                                                <Countdown date={Date.now() + 15000}>
                                                <a className="btn btn-primary" href="/md2m8spanish"> Siguiente diapositiva </a>
            </Countdown>
            </div>
        )
    }
}