import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2Film extends Component {

    render() {

        return (
            <div className="container">

                <iframe width="560" height="315" src="https://www.youtube.com/embed/NrqgyVw7hvI" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>                             

                
                <a className="btn btn-primary" href="/md2spanish">Next Slide</a>                                      
            </div>
                                                )
    }
}