import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz1Spanish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q2Spanish",
            questions: [
                {
                                        id: 1,
                                        text: "Sería considerado ausente de la escuela debido a unas vacaciones familiares",
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Excusado',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Injustificado',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Contado como presente',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Contado como dos ausencias',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 2,
                                        text: "Estar ausente de la escuela debido a una suspensión se consideraría una ausencia ilegal",
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 3,
                                        text: "Estar ausente de la escuela debido a la celebración de una festividad religiosa se consideraría una ausencia legal",
                                            choices: [
                                                    {
                                                            id: 'a',
                                                            text: 'Verdadero',
                                                    },
                                                    {
                                                            id: 'b',
                                                            text: 'Falso',
                                                    }
                                            ],
                                        correct: 'a'
                                },
                                {
                                        id: 4,
                                        text: 'Se considera una ausencia justificada cuando su hijo está',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Inicio con amigos',
                                                },
                                                {
                                                        id: 'b',
                                                        text: "Obligado a comparecer ante el tribunal",
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Familia de visita fuera del estado',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'De vacaciones',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 5,
                                        text: 'Los estudiantes que están fuera de la escuela con una enfermedad deberían',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Regresen a la escuela cuando estén bien',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Proporcionar documentación para su ausencia a su regreso',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Ver a su maestra para el trabajo de recuperación',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Todo lo anterior',
                                                }
                                        ],
                                        correct: 'd'
                                },
                                {
                                        id: 6,
                                        text: 'El director de la escuela o el trabajador del personal del alumno pueden requerir un certificado del médico para excusar las ausencias del estudiante que son excesivas.',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 7,
                                        text: 'Los estudiantes que habitualmente faltan a clase pueden ser puestos bajo la custodia de servicios sociales',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'a'
                                },
                {
                                        id: 8,
                                        text: 'Se consideran estudiantes que están ausentes de la escuela debido a la muerte de un familiar inmediato',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Excusado',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Injustificado',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Truant',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'En riesgo',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 9,
                                        text: 'La Ley de Asistencia Escolar Obligatoria de Maryland exige que todos los niños asistan a la escuela entre las edades de',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: '5 a 16',
                                                },
                                                {
                                                        id: 'b',
                                                        text: '7 a 18',
                                                },
                                                {
                                                        id: 'c',
                                                        text: '5 a 18',
                                                },
                                                {
                                                        id: 'd',
                                                        text: '6 a 16',
                                                }
                                        ],
                                        correct: 'c'
                                },
                                {
                                        id: 10,
                                        text: 'Las ausencias de los estudiantes que han sido registrados como personas sin hogar siempre se considerarán justificadas',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 11,
                                        text: 'Las siguientes sanciones se pueden dar a los padres / tutores que han sido encontrados culpables de violar la Ley de Asistencia Escolar Obligatoria de Maryland',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'encarcelamiento',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Multas',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Pérdida de fondos AFDC',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Todo lo anterior',
                                                }
                                        ],
                                        correct: 'd'
                                },
                                {
                                        id: 12,
                                        text: '¿Quién es legalmente responsable de que un niño asista a la escuela regularmente y a tiempo conforme a la Ley de Asistencia de la Escuela Obligatoria de Maryland?',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Padre / Tutor',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Maestro del niño',
                                                },
                                                {
                                                        id: 'c',
                                                        text: 'Niño',
                                                },
                                                {
                                                        id: 'd',
                                                        text: 'Todo lo anterior',
                                                }
                                        ],
                                        correct: 'a'
                                },
                                {
                                        id: 13,
                                        text: 'Se requiere que los maestros permitan que los estudiantes completen todas las tareas perdidas después de una ausencia injustificada.',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: 'Verdadero',
                                                },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                        ],
                                        correct: 'b'
                                },
                                {
                                        id: 14,
                                        text: 'El Departamento de Educación del Estado de Maryland considera que la asistencia satisfactoria es',
                                        choices: [
                                                {
                                                        id: 'a',
                                                        text: '85% ',
                                                },
                                                {
                                                        id: 'b',
                                                        text: '94% ',
                                                },
                                                {
                                                        id: 'c', 
                                                        text: '90%',
                                                }, 
                                                {       
                                                        id: 'd', 
                                                        text: '84%', 
                                                }
                                            ],
                                            correct: 'b'
                                },
                                {
                                    id: 15,
                                    text: 'Cada persona declarada culpable de violar la ley de asistencia obligatoria de Maryland identificada con custodia legal o cuidado y control del niño puede ser encarcelada hasta 3 días para la primera condena.',
                                    choices: [
                                        {
                                                                        id: 'a',
                                                                        text: 'Verdadero',
                                                                },
                                                                {
                                                                        id: 'b',
                                                                        text: 'Falso',
                                                                }
                                    ],
                                    correct: 'a'
                                },
                                {
                                    id: 16,
                                    text: 'Cada persona declarada culpable de violar la ley de asistencia obligatoria de Maryland es culpable de un delito menor.',
                                    choices: [
                                        {
                                                                        id: 'a',
                                                                        text: 'Verdadero',
                                                                },
                                                                {
                                                                        id: 'b',
                                                                        text: 'Falso',
                                                                }
                                    ],
                                    correct: 'a'
                                },
                                {
                                    id: 17,
                                    text: 'El Departamento de educación del estado de Maryland considera que la asistencia',
                                    choices: [
                                        {
                                                                        id: 'a',
                                                                        text: 'Verdadero',
                                                                },
                                                                {
                                                                        id: 'b',
                                                                        text: 'Falso',
                                                                }
                                    ],
                                    correct: 'b'
                                },
                                {
                                    id: 18,
                                    text: 'Los sistemas escolares de Maryland tienen la autoridad de presentar nuevos cargos contra cada persona que tenga custodia legal o cuidado y control de un niño de entre 17 y 18 años de edad por violar la ley de asistencia obligatoria de Maryland.',
                                    choices: [
                                        {
                                                                        id: 'a',
                                                                        text: 'Verdadero',
                                                                },
                                                                {
                                                                        id: 'b',
                                                                        text: 'Falso',
                                                                }
                                    ],
                                    correct: 'b'
                                }
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}
