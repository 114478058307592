import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_Review extends Component {

    render() {

        return (
            <div>
                <h1>Chapter 2 - Risk Factors and Statistics- Review</h1>

                <h2>Staggering Statistics </h2>
                <a href="https://ibb.co/V2q3gbZ"><img src="https://i.ibb.co/Tmgr4JS/2014-salary-projections.jpg" alt="2014-salary-projections" border="0"></img></a>
                    <ul>
                        <li>Every 26 seconds a student drops out of school</li>
                            <li>Every year, more than 1 million children drop out of school</li>
                                <li>8 out of 10 dropouts end up in prison</li>
                                    <li>The United States spends approximately 40 billion dollars each year on prisoner incarceration</li>
                                        <li>A high school dropout will earn 1 million dollars less over their lifetime than a college graduate</li>
                                            <li>High school dropouts cost tax payers billions of dollars in lost wages, welfare, unemployment, and crime related expenses</li>
                                                <li>Source: Mattie C. Stewart Foundation, Dr. Shelley Stewart, 2009</li>
                    </ul> 


<h2>Student Dropout Factors</h2>
                    <ul>
                        <li>Poor academic performance</li>
                            <li>Alcohol and drug use</li>
                                <li>Unmet mental health needs</li>
                                    <li>Lack of educational goals</li>
                                        <li>Poor physical health</li>
                                            <li>Truant friends</li>
                                                <li>Teen pregnancy</li>
                                                    <li>Lack of childcare </li>
                                                        <li>Lack of sleep</li>
                                                        </ul>
                                        
                                                        <h2>Family Dropout Factors</h2>
                    <ul>
                        <li>Lack of parental supervision or guidance</li>
                            <li>Substance abuse</li>
                                <li>Domestic violence</li>
                                    <li>Unavailable to provide childcare for grandchild of teenage parent</li>
                                        <li>Child neglect</li>
                                            <li>Student providing care to family members with failing health</li>
                                                <li>Family history of dropping out of school</li>
                                                </ul>
                                                
                                                <h2>School Dropout Factors</h2>
                    <ul>
                        <li>Lack of effective and consistently applied attendance policies</li>
                            <li>Poor record-keeping, making truancy difficult to spot</li>
                                <li>Parents/guardians not notified of absences daily</li>
                                    <li>"Push Out" policies consisting of suspensions and automatic failing grades as consequences for truancy</li>
                                        <li>Unsafe school environment which consists of ineffective discipline policies resulting in high incidence of bullying.</li>
                                        </ul>
             
                                        <h2>Impact of Truancy</h2>
                    <ul>
                        <li>Social Impact</li>

                            <li>Behavioral Impact</li>

                                <li>Economic Impact</li>
</ul>

<h2>Social Impact.</h2>
                    <ul>
                        <li>Disengagement from peers and school community</li>
                            <li>Isolation from extracurricular activities</li>
                                <li>School removal due to delinquent behavior</li>
                                    <li>Dropout</li>
                                        <li>Complacency with life without school</li>
                                            <li>Removal of school support system </li>

</ul>

<h2>Economic Impact</h2>
                    <ul>
                        <li>Schools lose state educational funding due to high dropout rate</li>
                            <li>Dropouts have a higher probability to be unemployed</li>
                                <li>Dropouts have a higher probability to be receiving Cash or Medical Assistance from the State Government.</li>
                                    <li>Dropouts have a higher probability to be incarcerated and state taxpayers fray the housing cost.</li>
                                        <li>Dropouts have a higher probability to be living in poverty.</li>
                                            <li>The more education an individual attains, the more potential he/she has for increasing their potential salary. </li>
                                                <li>See Economic Salary Graph (next page)</li>
                                                </ul>
                    
                                                <Countdown date={Date.now() + 120000}>  
                <a className="btn btn-primary" href="/quiz2">Start Quiz</a>
                </Countdown>
            </div>
        )
    }
}