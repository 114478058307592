import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_6Spanish extends Component {

    render() {

        return (
            <div>
                <h1> Definici n de ausencias legales e ilegales </h1>
                        <h2> Ausencias legales: las ausencias de la escuela son legales solo bajo las siguientes condiciones: </h2>
                            <ul>
                                <li> Muerte en familia </li>
                                    <li> Enfermedad del ni o </li>
                                        <li> Invocaciones judiciales </li>
                                            <li> Condiciones clim ticas peligrosas </li>
                                                <li> Trabajo aprobado </li>
                                                    <li> Fiesta religiosa </li>
                                                        <li> Emergencia estatal </li>
                                                            <li> Otra emergencia </li>
                                                                <li> Exclusi n de salud </li>
                                                                    <li> Suspensi n </li>
                                                                        <li> Falta de transporte autorizado </li>
                </ul>
            
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md1m7spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>)
    }
}