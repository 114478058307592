import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Anchor } from 'antd';
const { Header, Content, Footer } = Layout;
const { Link } = Anchor;



export class LayoutPanel extends Component {
    display
    constructor(props) {
        super(props);

    }

    goTo(route) {
        this.props.history.replace(`/${route}`)
    }

    logout() {
        this.props.auth.logout();
    }

    displayName = LayoutPanel.name
    render() {
        const { isAuthenticated } = this.props.auth;

        return (
            <Layout className="layout">
                <Header>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        style={{ lineHeight: '64px' }}
                    >
                        <Menu.Item key="1">
                            <a href="/">Home</a>
                        </Menu.Item>
                        <Menu.Item key="2">
                            < a href = "/start">Test</a>
                        </Menu.Item >
                        <Menu.Item key="3">
                            <a href = "/about"> About </a>
                        </Menu.Item>
                        {
                            isAuthenticated() && (
                                <Menu.Item key="4">
                                    <a onClick={this.logout.bind(this)}> Logout </a>
                                </Menu.Item>
                            )
                        }
                    </Menu>
                </Header>
                <Content style={{ padding: '0 50px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        
                    </Breadcrumb>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>{this.props.children}</div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    MD Truancy Diversion 2018
                </Footer>
            </Layout>
        )
    }
}
