import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_6Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha estado ausente recientemente debido a la familia
                    reubicarse en una nueva área debido a dificultades financieras.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con los funcionarios de la escuela para ver si cumple con los criterios para
                    haga que su hijo sea aprobado bajo McKinney-Vento Homeless
  Ley de Asistencia <br />
                        <br />
                    </ li>
                    <li> Los padres pueden considerar transferir al niño a la escuela en
                    la nueva zona de asistencia
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo tiene miedo de ir a la escuela y ha sido diagnosticado
                    con fobia a la escuela
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con la escuela del niño para programar una reunión para abordar este tema
                    preocupación y la posible necesidad de:
    <ul>
                            <li> Servicios de consejería
      </ li>
                            <li> un programa de escuela diurna modificado
      </ li>
                            <li> Servicios de hogar y hospital
      </ li>
                            <li> un programa de escolarización en el hogar
      </ li>
                        </ ul>
                    </ li>
                </ ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m7">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}