import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_2Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Declaración jurada informal sobre el cuidado de parientes </ h2>
                <ul>
                    <li>
                        <strong> Cuidado de parentesco informal: </ strong> se refiere a una vida
                        acuerdo en el que un pariente de un niño, que no está en el
                        cuidado, custodia o tutela del departamento local de
                        servicios sociales, provee cuidado y custodia del niño
                        debido a dificultades familiares graves, las 24 horas del día, siete días a
                        semana. Esto solo se aplica si el padre / tutor que enfrenta al
                        dificultad es un residente de Maryland.
  </ li>
                </ ul>
                <p>
                    <strong> Elegibilidad de dificultades familiares </ strong>
                </ p>
                <ul>
                    <li> Muerte del padre o tutor legal. <br />
                        <br />
                    </ li>
                    <li> Enfermedad grave del padre o tutor legal. <br />
                        <br />
                    </ li>
                    <li> Drogadicción del padre o tutor legal <br />
                        <br />
                    </ li>
                    <li> Encarcelamiento de padre o tutor legal <br />
                        <br />
                    </ li>
                    <li> Abandono del niño <br />
                        <br />
                    </ li>
                    <li> Asignación de padre o tutor legal a militares activos
  deber. <br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong> Documentos solicitados para determinar el estado de aprobación de
  Declaración jurada de cuidado de parentesco informal: </ strong>
                </p>
                <ul>
                    <li> Copia del certificado de defunción del padre o tutor legal. <br />
                        <br />
                    </ li>
                    <li> Documentación médica de una enfermedad grave del médico de
  padre / tutor legal <br />
                        <br />
                    </li>
                    <li> Documentación del centro de detención donde los padres / legal
  tutor está encarcelado. <br />
                        <br />
                    </li>
                    <li> Otra documentación de varias agencias, como las redes sociales
                    servicios, tribunales, militares, etc. que verifiquen los padres / legal
                    dificultad guardiana.
  </li>
                </ul>
                <p>
                    <strong> Determinación de la ubicación en la escuela: </ strong>
                </ p>
                <ul>
                    <li> El familiar de un niño dentro del 5 ° grado de sangre o
                    el matrimonio debe brindar cuidado dentro de la zona de asistencia escolar en
                    que él / ella actualmente reside.
  </li>
                </ul>
                <p>
                    <strong> Obtención de la declaración jurada de cuidado familiar informal: </ strong>
                </ p>
                <ul>
                    <li> Consejo de educación local
  </li>
                    <li> Departamento de Servicios Sociales
  </li>
                    <li> Oficina local de envejecimiento
  </li>
                </ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}