import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_4Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo tiene dificultades para levantarse por la mañana.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Explore diferentes métodos para ayudar al niño a despertarse
                    escuela (es decir, despertador, levante al niño más temprano para prepararse
  para la escuela, haga que el niño se acueste más temprano, etc.) <br />
                        <br />
                    </ li>
                    <li> Haga que un pediatra vea al niño para un posible sueño
  trastorno <br />
                        <br />
                    </ li>
                    <li> Investigue si el niño sería elegible para un Hogar
  y programa hospitalario debido a un trastorno del sueño <br />
                        <br />
                    </ li>
                    <li> Considere colocar al niño en un programa de educación en el hogar
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> Mi hijo ha estado ausente recientemente y ahora reside con
                    otros parientes debido a una dificultad familiar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Familiar debe contactar al sistema escolar local para explorar
  políticas de inscripción en condiciones difíciles <br />
                        <br />
                    </ li>
                    <li> Pariente puede ser aprobado para inscribir a un niño bajo Informal
  Declaración jurada de Kinship Care. <br />
                        <br />
                    </ li>
                    <li> Un familiar puede ser aprobado para inscribir a un niño bajo McKinney-Vento
  Ley de asistencia a personas sin hogar <br />
                        <br />
                    </ li>
                    <li> Pariente puede ser aprobado para inscribir a un niño en la escuela local
                    bajo la inscripción de no residentes del distrito escolar aprobado
                    política
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
<a className="btn btn-primary" href="/md4m5">Next Slide</a>
</Countdown>
            </div>
        )
    }
}