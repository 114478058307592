import React, { Component } from 'react';

export default class Index extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <p>
                    <strong>Welcome to MD Truancy Diversion,
  Inc.</strong>
                </p>
                <p>
                    The most widely accepted online truancy education and drop-out
                    prevention class for parents/guardians. This is an organization aimed at empowering parents. This course
                    is designed to serve as a mandatory truancy education class for
                    parents/guardians and has been approved by school and court
                    systems. It is also available to people who are eagerly
                    interested in learning more about truancy, drop-out, and the
                    effective parenting skills needed to avoid these issues. The goal
                    of this program is to combat national truancy and drop-out rates
                    through the education of parents/guardians. After taking this
                    class, parents/guardians will have a full understanding of the:
</p>
                <ul>
                    <li>Compulsory School Attendance Law for their state
  </li>
                    <li>effects of truancy and drop-out
  </li>
                    <li>positive strategies they can employ to reduce their child's
                    truancy
  </li>
                    <li>relationship between consistent school attendance, academics,
                    and the completion of a school program
  </li>
                </ul>
                <p>
                    By mandating that the parents/guardians of truant students take
                    this online class, school and court systems can further strive
                    to:
</p>
                <ul>
                    <li>increase parent involvement in the education of their
                    children
  </li>
                    <li>increase the graduation rate of students nationwide
  </li>
                    <li>identify and eliminate the risk factors associated with
                    students who drop-out of school
  </li>
                    <li>provide resources that may address the personal, financial,
                    medical, and mental health needs of families
  </li>
                    <li>assist individual schools in achieving their Annual Yearly
                    Progress goals
  </li>
                </ul>
                
                <p>
                    <strong>TAKING THE CLASS</strong><br />
                    Please read ALL of the instructions as they contain the most
                    common questions asked when completing the online Truancy
                    Education Class. To take the online class, please click on the
                    link below. Each person to receive a certificate must register
                    and pay before beginning the class. Once you register and pay,
                    using our secure server, you will be directed to the Chapter 1
                    start page.
</p>
                <p>
                    The slides for your online class are timed and you are not
                    permitted to move forward to subsequent chapters until you have
                    passed a Chapter Quiz with a minimum score of 80%. If you fail a
                    Chapter Quiz, you will need to repeat the timed slides for that
                    particular chapter and take the quiz again. When you have passed
                    all chapter quizzes, you will receive a computer generated
                    certificate for your completion of the program. We hope you find
                    this class convenient, personal, and meaningful.
</p>
                <p>
                    To take the Truancy Education Class online, and at your own
                    speed.
</p>
                <div align="center">
                    <a href="signup.html"><img src="images/register.jpg" alt=""
                        border="0" /></a>
                </div>
                <div align="center">
                    <strong><a href="/resources">Resources and
  Downloads</a></strong><br />

                </div>
            </div>
        )
    }
}
