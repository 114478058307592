import React, { Component } from 'react';

export default class Index extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <p>
                    <strong>Raymond Anderson</strong><br />
                    Raymond Anderson received his BA in Sociology from Salisbury State University, and his M. Ed. in School Counseling from Bowie State University. He has spent the last twenty-three years working with elementary, middle, and high school students in the Maryland Public School system. He started in this field as a Professional School Counselor in Prince George's County before taking a position as a Pupil Personnel Worker for Baltimore County Public Schools in 1999. During this same year he acquired his Advanced Certificate in Educational Administration from Loyola College. 
</p>
                <p>
                    All of his years in education have given Raymond first-hand experience working with thousands of at-risk students, many of whom were in danger of dropping out of school. Raymond is continuously faced with parents that struggle with or neglect to send their children to school on a daily basis. In doing so, he strives to educate and provide resources to them, while also enforcing the Maryland Compulsory School Attendance Law. In addition, he partners with professional agencies to give families the tools they need to function at their very best; helps student�s access educational possibilities; and motivates students, parents, and school staff to reach their personal and professional goals. 

</p>
                <p>
                    Raymond also has experience working with at-risk, delinquent youth committed by the State of Maryland to residential facilities and has years of experience in the Mental Health field. 
</p>
                <p>
                    Additionally, Raymond's experiences, along with his recognition of the need for more effective resources for parents, lead him to co-developing "Making a Difference: Truancy Education Program". His goal is to utilize this program to educate parents on how to advocate for their at-risk child, while also working to erase the egregious dropout rate that is becoming an epidemic across the nation. 
                </p>
                <p>Further, Raymond�s truancy diversion class goal is to combat truancy and drop-out rates through the education of parents/guardians and professional development to court systems, states attorneys, public defenders, school systems and various agencies that are stakeholders in serving at-risk families. Moreover, to improve incarceration rates, reduction in fines and court cost associated with violating the Compulsory Attendance Law.
</p>
            </div>
        )
    }
}