import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_2Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Factores de abandono del estudiante </h2>
                <ul>
                    <li> Bajo rendimiento acad mico </li>
                    <li> Consumo de alcohol y drogas </li>
                    <li> Necesidades de salud mental no satisfechas </li>
                    <li> Falta de objetivos educativos </li>
                    <li> Mala salud f sica </li>
                    <li> Amigos sin permiso </li>
                    <li> Embarazo adolescente </li>
                    <li> Falta de cuidado de ni os </li>
                    <li> Falta de sue o </li>
                </ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m3spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
        )
    }
}