import React, { Component } from 'react';
import { Button } from 'antd';
import Countdown from 'react-countdown-now';

export default class MD2Film extends Component {

    render() {

        return (
            <div className="container">

                <iframe src="https://player.vimeo.com/video/280435809" width="640" height="480" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <Button type="primary" href="/md2">Go to Chapter 2</Button>                                  
            </div>
                                                )
    }
}