import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_ReviewSpanish extends Component {

    render() {

        return (
            <div>
                <h1> Cap tulo 2 - Factores de riesgo y estad sticas- revise </h1>

<h2> Estad sticas asombrosas </h2>
                    <ul>
                        <li> Cada 26 segundos que un alumno abandona la escuela </li>
                            <li> Cada a o, m s de 1 mill n de ni os abandonan la escuela </li>
                                <li> 8 de cada 10 desertores terminan en prisi n </li>
                                    <li> Estados Unidos gasta aproximadamente 40 mil millones de d lares cada a o en encarcelamiento de prisioneros </li>
                                        <li> Un desertor de la escuela secundaria ganar  1 mill n de d lares menos durante su vida que un graduado universitario </li>
                                            <li> Los desertores de la escuela secundaria les cuestan a los contribuyentes miles de millones de d lares en salarios perdidos, bienestar, desempleo y gastos relacionados con la delincuencia </li>
                                                <li> Fuente: Fundaci n Mattie C. Stewart, Dra. Shelley Stewart, 2009 </li>
                    </ul>


<h2> Factores de abandono del estudiante </h2>
                    <ul>
                        <li> Bajo rendimiento acad mico </li>
                            <li> Consumo de alcohol y drogas </li>
                                <li> Necesidades de salud mental no satisfechas </li>
                                    <li> Falta de objetivos educativos </li>
                                        <li> Mala salud f sica </li>
                                            <li> Amigos sin permiso </li>
                                                <li> Embarazo adolescente </li>
                                                    <li> Falta de cuidado de ni os </li>
                                                        <li> Falta de sue o </li>
                                                        </ul>
                                        
                                                        <h2> Factores de abandono de la familia </h2>
                    <ul>
                        <li> Falta de supervisi n u orientaci n de los padres </li>
                            <li> Abuso de sustancias </li>
                                <li> Violencia dom stica </li>
                                    <li> No est  disponible para proporcionar cuidado de ni os para el nieto de un padre adolescente </li>
                                        <li> Descuido de ni os </li>
                                            <li> Estudiante que proporciona atenci n a miembros de la familia con problemas de salud </li>
                                                <li> Historial familiar de abandono escolar </li>
                                                </ul>
                                                
                                                <h2> Factores de abandono escolar </h2>
                    <ul>
                        <li> Falta de pol ticas de asistencia efectivas y aplicadas consistentemente </li>
                            <li> Pobre mantenimiento de registros, lo que hace que el ausentismo sea dif cil de detectar </li>
                                <li> Los padres / tutores no son notificados de las ausencias diarias </li>
                                    <li> Pol ticas de "expulsi n" que consisten en suspensiones y calificaciones reprobables autom ticas como consecuencia del absentismo escolar </li>
                                        <li> Ambiente escolar inseguro que consiste en pol ticas de disciplina ineficaces que resultan en una alta incidencia de bullying. </li>
                                        </ul>
             
                                        <h2> Impacto del absentismo escolar </h2>
                    <ul>
                        <li> Impacto social </li>

                            <li> Impacto en el comportamiento </li>

                                <li> Impacto econ mico </li>
</ul>

<h2> Impacto social. </h2>
                    <ul>
                        <li> Desconexi n de compa eros y comunidad escolar </li>
                            <li> Aislamiento de actividades extracurriculares </li>
                                <li> Expulsi n escolar debido a un comportamiento delictivo </li>
                                    <li> Deserci n </li>
                                        <li> Complacencia con la vida sin escuela </li>
                                            <li> Eliminaci n del sistema de apoyo escolar </li>

</ul>

<h2> Impacto econ mico </h2>
                    <ul>
                        <li> Las escuelas pierden fondos educativos estatales debido a la alta tasa de deserci n </li>
                            <li> Los abandonos tienen una mayor probabilidad de estar desempleados </li>
                                <li> Los abandonos tienen una mayor probabilidad de recibir efectivo o asistencia m dica del gobierno estatal. </li>
                                    <li> Los desertores tienen una probabilidad m s alta de ser encarcelados y los contribuyentes estatales defraudan el costo de la vivienda. </li>
                                        <li> Los abandonos tienen una mayor probabilidad de vivir en la pobreza. </li>
                                            <li> Mientras m s educaci n alcanza un individuo, m s potencial tiene para aumentar su salario potencial. </li>
                                                <li> Ver gr fico de salario econ mico (p gina siguiente) </li>
                                                </ul>
                    
                                                <Countdown date={Date.now() + 120000}>
                <a className="btn btn-primary" href="/quiz2spanish"> Iniciar prueba </a>
                </Countdown>
            </div>
        )
    }
}