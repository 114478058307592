import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_2 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>It is impossible for my child to attend school regularly due
  to a chronic illness or physical disability.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Student may be eligible for a modified school program<br />
                        <br />
                    </li>
                    <li>Explore whether the child is eligible for a 504 Plan<br />
                        <br />
                    </li>
                    <li>Explore Home &amp; Hospital Services<br />
                        <br />
                    </li>
                    <li>Enroll in an approved Home Schooling program
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child refuses to attend school regularly due to him/her
  struggling academically.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Set-Up Teacher Conference<br />
                        <br />
                    </li>
                    <li>Explore free tutoring services<br />
                        <br />
                    </li>
                    <li>Request for your child to be teamed to explore if he/she
                    qualifies for additional educational services. Child may have an
  educational disability that qualifies for IEP services<br />
                        <br />
                        Print attached <a href="list2.html" target="_blank">"Request
    for an IEP Team"</a> sample letter
  </li>
                </ul>

<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}