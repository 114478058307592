import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_6 extends Component {

    render() {

        return (
            <div>
                <h2>Barriers and Resources</h2>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child has been absent recently due to the family
                    relocating to a new area because of financial hardship.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact school officials to see if you meet the criteria to
                    have your child approved under the McKinney-Vento Homeless
  Assistance Act<br />
                        <br />
                    </li>
                    <li>Parent may consider transferring the child to the school in
                    the new attendance zone
  </li>
                </ul>
                <p>
                    <strong>Barrier</strong><br />
                </p>
                <ul>
                    <li>My child is afraid to attend school and has been diagnosed
                    with school phobia.
  </li>
                </ul>
                <p>
                    <strong>Options to Address Concern</strong><br />
                </p>
                <ul>
                    <li>Contact child's school to set up a meeting to address this
                    concern and the possible need for:
    <ul>
                            <li>Counseling Services
      </li>
                            <li>a modified day school program
      </li>
                            <li>Home and Hospital Services
      </li>
                            <li>a Home Schooling Program
      </li>
                        </ul>
                    </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md4m7">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}