import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz3Spanish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "Q4",
            questions: [
                {
                                        id: 1,
                                        text: 'Una declaración jurada informal sobre el cuidado de parientes permite que familiares que no sean padres biológicos / tutores legales inscriban a un niño en una escuela pública de Maryland',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 2,
                                        text: 'De acuerdo con la Declaración jurada informal de parentalidad, el solicitante debe ser un pariente que brinda cuidados a un niño siete días a la semana, veinticuatro horas al día, debido a una dificultad familiar que involucra a un padre biológico / tutor legal',
                                            choices: [{
                                                    id: 'a',
                                                    text: 'Verdadero',
                                            },
                                                {
                                                        id: 'b',
                                                        text: 'Falso',
                                                }
                                            ],
                                        correct: 'a'
                                }, {
                                        id: 3,
                                        text: 'Los servicios de atención domiciliaria y hospitalaria se pueden proporcionar a los estudiantes con',
                                        choices: [{
                                                id: 'a',
                                                text: 'Condiciones emocionales diagnosticadas',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Condiciones físicas diagnosticadas',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Ninguna respuesta dada',
                                        }, {
                                                id: 'd',
                                                text: 'Ambas respuestas dadas',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 4,
                                        text: 'Un niño cuyo padre / tutor se muda voluntariamente fuera del estado cumpliría con los criterios para una matrícula de dificultad por un pariente',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 5,
                                        text: 'Una Evaluación de Comportamiento Funcional (FBA) típicamente se crea con la entrada de',
                                        choices: [{
                                                id: 'a',
                                                text: 'Padre / Tutor',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Estudiante',
                                        }, {
                                                id: 'c',
                                                text: 'Personal de la escuela',
                                        }, {
                                                id: 'd',
                                                text: 'Todo lo anterior',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 6,
                                        text: 'Un Plan 504 es un plan educativo que brinda servicios a estudiantes con un impedimento físico o mental que limita sustancialmente una o más actividades principales de la vida',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 7,
                                        text: '__________ se define como estar expuesto a acciones repetidas y negativas por parte de una o más personas a lo largo del tiempo cuando él / ella tiene dificultades para defenderse',
                                        choices: [{
                                                id: 'a',
                                                text: 'Home Schooling',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Bullying',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Dropout',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Educación especial',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 8,
                                        text: 'Un Plan de Intervención de Conducta (BIP) se utiliza para ayudar solo a los estudiantes que tienen una discapacidad de aprendizaje',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                }, {
                                        id: 9,
                                        text: 'Un Plan de Educación Individualizado (IEP) proporciona servicios educativos adicionales basados ​​en las necesidades de un niño identificado con una discapacidad y es un mandato del gobierno federal',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 10,
                                        text: 'Lo siguiente sería un ejemplo de intimidación',
                                        choices: [{
                                                id: 'a',
                                                text: 'Un estudiante se topa con otro estudiante deliberadamente en la sala',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Un estudiante es elegido por última vez para un equipo en la clase de gimnasia',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Un estudiante exige dinero para el almuerzo de otro estudiante todas las mañanas',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Una estudiante siente que se burlaría si otros tuvieran conciencia de su orientación sexual',
                                        }
                                        ],
                                        correct: 'c'
                                }, {
                                        id: 11,
                                        text: 'Un padre que abandonó la escuela secundaria todavía tiene derecho a la escuela de su hijo',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 12,
                                        text: 'La Ley McKinney-Vento Homeless Assistance se aplicaría a las familias que viven en',
                                        choices: [{
                                                id: 'a',
                                                text: 'Refugios',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Moteles',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Ninguna respuesta dada',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Ambas respuestas dadas',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 13,
                                        text: 'Un estudiante puede ser elegible para servicios de hogar y hospital debido a',
                                        choices: [{
                                                id: 'a',
                                                text: 'Embarazo',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Fobia a la escuela',
                                        }, {
                                                id: 'c',
                                                text: 'Cáncer',
                                        }, {
                                                id: 'd',
                                                text: 'Todo lo anterior',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 14,
                                        text: 'Un plan 504 puede ser apropiado para un niño que',
                                        choices: [{
                                                id: 'a',
                                                text: 'Está en una silla de ruedas',
                                        },
                                        {
                                                id: 'b',
                                                text: 'elige ser habitualmente ausente',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Tiene un historial familiar de abandono',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Todo lo anterior',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 15,
                                        text: 'Lo siguiente sería considerado una dificultad familiar en la Declaración Jurada de Cuidado de Parentesco Informal',
                                        choices: [{
                                                id: 'a',
                                                text: 'Encarcelamiento del padre / tutor legal',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Muerte del padre / tutor legal',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Ninguna respuesta dada',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Ambas respuestas dadas',
                                        }
                                        ],
                                        correct: 'd'
                                },
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}