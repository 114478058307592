import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD4_5Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Barreras y recursos </ h2>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> El niño no asiste a la escuela debido al estado de "Extranjero ilegal" de
                    padres
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Póngase en contacto con el distrito escolar de forma anónima para explorar cómo
  dirigirse a educar a los estudiantes bajo este estado <br />
                        <br />
                    </ li>
                    <li> Explore programas aprobados de educación en el hogar
  </ li>
                    <li> El niño puede ser aprobado en virtud de una política de inscripción por dificultades económicas de
                    sistema escolar local.
  </ li>
                    <li> El niño puede ser aprobado bajo el "McKinney Vento Homeless"
                    Ley de asistencia
  </ li>
                </ ul>
                <p>
                    <strong> Barrera </ strong> <br />
                </ p>
                <ul>
                    <li> El niño está ausente debido a la falta de residencia de los padres
                    documentación que verifica que son un nuevo inquilino en la zona escolar.
  </ li>
                </ ul>
                <p>
                    <strong> Opciones para abordar la preocupación </ strong> <br />
                </ p>
                <ul>
                    <li> Comuníquese con el distrito escolar para explorar quién está asignado a
  investigar y aprobar casos especiales de inscripción <br />
                        <br />
                    </ li>
                    <li> Visite la escuela local para solicitar dirección para el manejo de la residencia
                    verificación
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
<a className="btn btn-primary" href="/md4m6">Next Slide</a>
</Countdown>
            </div>
        )
    }
}