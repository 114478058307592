import React, { Component } from 'react';
import Countdown from 'react-countdown-now';


export default class MD3 extends Component {

    render() {

        return (
            <div>
                <h2>Resources Defined</h2>
                <ul>
                    <li>Informal Kinship Care Affidavit</li>
                    <li>Home and Hospital Services</li>
                    <li>Individualized Education Plan (IEP)</li>
                    <li>504 Plan</li>
                    <li>Functional Behavioral Assessment (FBA)</li>
                    <li>Behavior Intervention Plan (BIP)</li>
                    <li>Bullying</li>
                    <li>Home Schooling</li>
                    <li>McKinney-Vento Homeless Assistance Act</li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m2">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}