import React, { Component } from 'react';
import BoxScore from '../quiz/BoxScore.jsx';
import QuestionList from '../quiz/QuestionList';
import Results from '../quiz/Results.jsx';

export class Quiz4Spanish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextTest: "QFinal",
            questions: [
                {
                                        id: 1,
                                        text: 'Las escuelas públicas de Maryland no tienen una política con respecto a la intimidación / acoso',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 2,
                                        text: 'Un estudiante puede ser suspendido de la escuela por intimidar a otro estudiante',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 3,
                                        text: 'Un estudiante cuyos padres son considerados extranjeros ilegales ',
                                        choices: [{
                                                id: 'a',
                                                text: 'Todavía puede inscribirse en la escuela',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Debe estar en el país por 30 días antes de matricularse en la escuela',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Debe registrarse como sin hogar',
                                        }, {
                                                id: 'd',
                                                text: 'Todo lo anterior',
                                        }
                                        ],
                                        correct: 'a'
                                }, {
                                        id: 4,
                                        text: 'Es apropiado solicitar que los funcionarios de la escuela realicen una evaluación de comportamiento funcional en un niño debido a su comportamiento perturbador crónico',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'a'
                }, {
                                        id: 5,
                                        text: 'Los estudiantes que se han trasladado debido a una dificultad financiera deben inscribirse en la nueva escuela zonal',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 6,
                                        text: 'No hay forma de que un padre se proteja legalmente a sí mismo de violar la Ley de Asistencia Escolar Obligatoria de Maryland cuando su hijo se niega a ir a la escuela',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 7,
                                        text: 'Los niños que tienen dificultades para ir a la escuela cada mañana deberían',
                                        choices: [{
                                                id: 'a',
                                                text: 'Consulte con su médico acerca de un trastorno del sueño',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Utilizar una alarma de reloj',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Intenta ir a la cama más temprano por la noche',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Todo lo anterior',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 8,
                                        text: 'Los padres que se van a trabajar antes de que su hijo se vaya a la escuela no son responsables de la asistencia de sus hijos',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 9,
                                        text: 'Una estudiante que tiene dificultades con su embarazo no tiene otra opción que asistir a su escuela normal hasta que nazca el bebé',
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                                }, {
                                        id: 10,
                                        text: 'Animamos a los padres / tutores de los niños que se han fugado',
                                        choices: [{
                                                id: 'a',
                                                text: 'Presentar un informe con las autoridades locales',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Informar al personal escolar de la situación',
                                        },
                                        {
                                                id: 'c',
                                                text: 'Haga que la escuela retire al niño permanentemente',
                                        },
                                        {
                                                id: 'd',
                                                text: 'Primeras dos respuestas',
                                        }
                                        ],
                                        correct: 'd'
                                }, {
                                        id: 11,
                                        text: "Las escuelas alientan a los padres / tutores de los niños que son constantemente perturbadores a mantenerlos lejos de la escuela los días en que no están de buen humor",
                                        choices: [{
                                                id: 'a',
                                                text: 'Verdadero',
                                        },
                                        {
                                                id: 'b',
                                                text: 'Falso',
                                        }
                                        ],
                                        correct: 'b'
                }, {
                    id: 12,
                    text: 'Cuando un administrador de la escuela recibe un informe escrito sobre la intimidación en su escuela, él / ella debe responder dentro de_____________',
                    choices: [{
                        id: 'a',
                        text: '1 hora',
                    },
                    {
                        id: 'b',
                        text: '1 semana',
                    },
                    {
                        id: 'c',
                        text: '2 días escolares',
                    },
                    {
                        id: 'd',
                        text: '24 horas ',
                    }
                    ],
                    correct: 'c'
                }
            ],
            score: 0,
            current: 1
        }
    }
    setCurrent(current) {
        this.setState({ current });
    }
    setScore(score) {
        this.setState({ score });
    }
    render() {
        if (this.state.current > this.state.questions.length) {
            var boxscore = '';
            var results = <Results {...this.state} />
        } else {
            var boxscore = <BoxScore {...this.state} />
            var results = '';
        }
        return (
            <div>
                {boxscore}
                <QuestionList setScore={this.setScore.bind(this)} setCurrent={this.setCurrent.bind(this)} {...this.state} />
                {results}
            </div>
        );
    }
}