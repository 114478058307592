import React, { Component } from 'react';
import Countdown from 'react-countdown-now';


export default class MD3_2 extends Component {

    render() {

        return (
            <div>
                <h2>Informal Kinship Care Affidavit</h2>
                <ul>
                    <li>
                        <strong>Informal Kinship Care-</strong> refers to a living
                        arrangement in which a relative of a child, who is not in the
                        care, custody, or guardianship of the local department of
                        social services, provides for the care and custody of the child
                        due to serious family hardship, 24 hours a day, seven days a
                        week. This only applies if the parent/guardian facing the
                        hardship is a Maryland resident.
  </li>
                </ul>
                <p>
                    <strong>Eligibility of Family Hardships</strong>
                </p>
                <ul>
                    <li>Death of parent or legal guardian.<br />
                        <br />
                    </li>
                    <li>Serious illness of parent or legal guardian.<br />
                        <br />
                    </li>
                    <li>Drug addiction of parent or legal guardian<br />
                        <br />
                    </li>
                    <li>Incarceration of parent or legal guardian<br />
                        <br />
                    </li>
                    <li>Abandonment of the child<br />
                        <br />
                    </li>
                    <li>Parent or legal guardian assignment to active military
  duty.<br />
                        <br />
                    </li>
                </ul>
                <p>
                    <strong>Documents requested to determine Approval Status of
  Informal Kinship Care Affidavit:</strong>
                </p>
                <ul>
                    <li>Copy of parent/legal guardian death certificate.<br />
                        <br />
                    </li>
                    <li>Medical documentation of serious illness from doctor of
  parent/legal guardian<br />
                        <br />
                    </li>
                    <li>Documentation from detention center where parent/legal
  guardian is incarcerated.<br />
                        <br />
                    </li>
                    <li>Other documentation from various agencies such as social
                    services, courts, military, etc� that verifies parent/legal
                    guardian hardship.
  </li>
                </ul>
                <p>
                    <strong>Determining School Placement:</strong>
                </p>
                <ul>
                    <li>The relative of a child within the 5th degree of blood or
                    marriage must provide care within the school attendance zone in
                    which he/she currently resides.
  </li>
                </ul>
                <p>
                    <strong>Obtaining Informal Kinship Care Affidavit:</strong>
                </p>
                <ul>
                    <li>Local Board of Education
  </li>
                    <li>Department of Social Services
  </li>
                    <li>Local Office of Aging
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m3">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}