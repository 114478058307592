import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_6Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Evaluación del comportamiento funcional (FBA) </ h2>
                <ul>
                    <li> Una evaluación de comportamiento funcional (FBA) es una solución de problemas
                    proceso para abordar el comportamiento problemático del estudiante. Desarrolla
                    técnicas y estrategias para identificar los propósitos de
                    comportamientos para ayudar a los equipos del IEP a seleccionar intervenciones directamente
                    abordar los problemas de comportamiento. Un FBA debe estar integrado en
                    el proceso de desarrollo, revisión y revisión de un estudiante
                    IEP. Este proceso debe incluir aportaciones del alumno,
  padre / tutor y personal de la escuela <br />
                        <br />
                    </ li>
                    <li> Una FBA se enfoca en identificar aspectos sociales significativos y específicos,
                    factores afectivos, cognitivos y / o ambientales asociados
                    con la ocurrencia (y la no ocurrencia) de comportamientos específicos de
                    un niño individual.
  </ li>
                </ ul>
                <p>
                    <br />
                    <strong> Plan de intervención de comportamiento (BIP) </ strong>
                </ p>
                <ul>
                    <li> Un plan de intervención de comportamiento (BIP) detalla cómo un equipo de IEP
                    mejorará el comportamiento difícil que es una barrera para el niño
                    éxito académico. Un BIP es un documento que describe cómo
                    El equipo de IEP ayudará a un niño a mejorar su comportamiento en instancias
                    cuando él / ella no puede enfocarse, no completa el trabajo, interrumpe el
                    aula, y / o constantemente está en problemas. Un BIP está en orden
                    cuando el comportamiento de un niño interrumpe el aula y significativamente
                    interrumpe su educación.
  </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m7">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}