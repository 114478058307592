import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD2_5Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Impacto del absentismo escolar </h2>
                <ul>
                    <li> Impacto social </li>

                    <li> Impacto en el comportamiento </li>

                    <li> Impacto econ mico </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md2m6spanish"> Siguiente diapositiva </a>
                </Countdown>
            </div>
        )
    }
}