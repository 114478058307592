import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD1_7 extends Component {

    render() {

        return (
            <div>
                <h1>Unlawful Absences</h1>
                    <ul>
                        <li>Truancy and all other absences not specifically listed as lawful.</li>  
                    </ul>
                                <b>Examples of Unlawful Absences:</b>
                                    <ul>
                                        <li>Parent/Guardian taking a child on vacation</li>  
                                            <li>Student staying home to provide care for younger sibling or parent</li>  
                                                <li>Student overslept </li>  
                                                    <li>Student exhausted from being awake all night playing game console or watching television</li>  
                                                        <li>Student Birthday</li>  
                                                            <li>Student employment is during school hours</li>  
                                                                <li>Student is homeless and therefore cannot come to school</li>  
</ul>
            
                
                <Countdown date={Date.now() + 15000}>
                    <a className="btn btn-primary" href="/md1m8">Next Slide</a>
                </Countdown>
            </div>)
    }
}