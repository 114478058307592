import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_9 extends Component {

    render() {

        return (
            <div>
                
                    <h2>McKinney-Vento Homeless Assistance Act</h2>

                <p>
                    This act applies to any person who:
</p>
                <ul>
                    <li>Lacks a regular, fixed and adequate nighttime residence (sub
  standard housing)<br />
                        <br />
                    </li>
                    <li>Is sharing housing due to economic struggles
  (doubled-up)<br />
                        <br />
                    </li>
                    <li>Is living in a shelter, hotel or motel<br />
                        <br />
                    </li>
                    <li>Is living in a public place not designed for sleeping (cars,
  parks)<br />
                        <br />
                    </li>
                    <li>Is an unaccompanied youth<br />
                        <br />
                    </li>
                    <li>Is a child or youth awaiting foster care placement<br />
                        <br />
                    </li>
                    <li>Is a child or youth abandoned in a hospital<br />
                        <br />
                    </li>
                    <li>Is a migrant child who qualifies under any of the above
  </li>
                </ul>
                <Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3mreview">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}