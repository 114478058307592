import React, { Component } from 'react';
import Countdown from 'react-countdown-now';

export default class MD3_3Spanish extends Component {

    render() {

        return (
            <div>
                <h2> Servicios de atención domiciliaria y hospitalaria </ h2>
                <ul>
                    <li>
                        <strong> Servicios de atención domiciliaria y hospitalaria: </ strong> el código de
                        El Reglamento de Maryland (COMAR) 13A.03.05.01 establece: "Estos
                        reglamentos que establecen un requisito mínimo, se aplican a
                        la provisión de servicios de instrucción para la escuela pública
                        estudiantes que no pueden participar en su escuela de
                        inscripción debido a una condición física o emocional ".
  </ li>
                </ ul>
                <p>
                    <strong> Cómo obtener una aplicación de hogar y hospital: </ strong>
                </ p>
                <ul>
                    <li> Comuníquese con el coordinador de hogar y hospital de su
                    distrito escolar local. Revise la Lista Maestra adjunta para ubicar el
                    coordinador local de hogar y hospital para su escuela respectiva
  distrito. <br />
                        <br />
                    </ li>
                </ ul>
<Countdown date={Date.now() + 15000}>
                <a className="btn btn-primary" href="/md3m4">Next Slide</a>
                </Countdown>
            </div>
        )
    }
}